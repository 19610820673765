import React from "react"
import { ICube } from "../../interfaces/cubes"
import { Link } from "react-router-dom"
import Icon from "@mdi/react"
import { mdiCubeOutline } from "@mdi/js"

interface IProps {
    cube: ICube
}

function CubeInfos(props: IProps) {
    return (
        <div className="tile cube-info">
            <div className="large-icon-container">
                <Icon path={mdiCubeOutline} />
            </div>
            <p>cube</p>
            <h2>{props.cube.name}</h2>
            <p>
                Régió: <Link to={`/regions/${props.cube.region._id}`}>{props.cube.region.name}</Link>{" "}
            </p>
            <p>
                Pozíció:{" "}
                {props.cube.location && props.cube.location.coordinates[0]
                    ? `${props.cube.location.coordinates[0].toFixed(4)} (lat) - ${props.cube.location.coordinates[1].toFixed(4)} (long)`
                    : "The location of the Spot is not specified"}
            </p>
        </div>
    )
}

export default CubeInfos
