//  Modules from the React eco-system
import { useState, useEffect, useContext } from "react"

// Third-party modules
import { toast } from "react-toastify"

// Own components
import SubPageNavbar from "../components/navigation/subpage-navbar.component"

// Navigation
import { useParams } from "react-router"
import { Switch, Route, useHistory } from "react-router-dom"
import supplierDetailsRoutes from "../routes/details/supplier-details.route"

// Redux, store
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"
import { UserContext } from "../store/UserProvider"

// Axios
import axiosClient from "../api/api"

// Interfaces, enums
import { ISupplier } from "../interfaces/persons"
import { ISubPageRoute } from "../interfaces/pages"
import Loader from "../components/loader.component"
import { ERoles } from "../constants/enum"

interface RouteParams {
    id: string
}

function SupplierDetails() {
    //Logged in user
    const { user } = useContext(UserContext)

    const dispatch = useDispatch()
    const history = useHistory()

    const params = useParams<RouteParams>()

    const [supplier, setSupplier] = useState<ISupplier | null>(null)

    useEffect(() => {
        fetchSupplier()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(setPageName({ pageName: "Suppliers", pageUrl: "/admin/suppliers/main", subPageName: supplier ? `${supplier.firstname} ${supplier.lastname}` : "" }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supplier])

    async function fetchSupplier() {
        try {
            if (params && params.id) {
                const response = await axiosClient.get(`/supplier/${params.id}`)
                const supplier: ISupplier = response.data
                displayWarning(supplier)
                setSupplier(supplier)
            }
        } catch (error) {
            toast("Failed to fetch supplier")
        }
    }

    function displayWarning(supplier: ISupplier) {

    }

    return (
        <div>
            <div>
                {supplier ? (
                    <div>
                        <SubPageNavbar
                            routes={supplierDetailsRoutes.filter((route: ISubPageRoute) => {
                                return route.permission?.includes(user.role as ERoles)
                            })}
                            id={supplier._id}
                        />

                        <Switch>
                            {supplierDetailsRoutes
                                .filter((route: ISubPageRoute) => {
                                    return route.permission?.includes(user.role as ERoles)
                                })
                                .map((route: ISubPageRoute, key: number) => {
                                    return (
                                        <Route
                                            key={key}
                                            path={route.path}
                                            exact
                                            render={() => {
                                                return (
                                                    <div>
                                                        <route.component supplier={supplier} setSupplier={setSupplier} />
                                                    </div>
                                                )
                                            }}
                                        />
                                    )
                                })}
                        </Switch>
                    </div>
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    )
}

export default SupplierDetails
