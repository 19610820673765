import RegionCubes from "../../components/regions/region-cubes.component"
import RegionHome from "../../components/regions/region-details-home.component"
import RegionDetailsOperator from "../../components/regions/region-details-operator.component"
import RegionSuppliers from "../../components/regions/region-suppliers.component"
import RegionUpdate from "../../components/regions/region-update.component"
import { ERoles } from "../../constants/enum"
import { ISubPageRoute } from "../../interfaces/pages"

const regionRoutes: ISubPageRoute[] = [
    {
        path: "/admin/regions/:id",
        name: "Details",
        component: RegionHome,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.REGIONADMIN, ERoles.SUPPLIER],
    },
    {
        path: "/admin/regions/:id/update",
        name: "Edit",
        component: RegionUpdate,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/regions/:id/cubes",
        name: "Spots",
        component: RegionCubes,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.REGIONADMIN],
    },
    {
        path: "/admin/regions/:id/operator",
        name: "Operator",
        component: RegionDetailsOperator,
        permission: [ERoles.SUPERADMIN],
    },
    {
        path: "/admin/regions/:id/suppliers",
        name: "Suppliers",
        component: RegionSuppliers,
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.REGIONADMIN],
    },
]

export default regionRoutes
