//  Modules from the React eco-system
import { useState, useEffect, useContext } from "react"
import i18next from "i18next";
import { useParams } from "react-router"

// Third-party modules
import { toast } from "react-toastify"

// Own components
import SubPageNavbar from "../components/navigation/subpage-navbar.component"
import Loader from "../components/loader.component"

// Navigation
import { Route, Switch, useHistory } from "react-router"
import cubesRoutes from "../routes/details/cube-details.route"

// Redux, store
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"
import { UserContext } from "../store/UserProvider"

// Axios
import axiosClient from "../api/api"

// Interfaces, enums
import { ICube } from "../interfaces/cubes"
import { ERoles } from "../constants/enum"
import { ISubPageRoute } from "../interfaces/pages"

interface RouteParams {
    id: string
}

function CubeDetails() {
    //Logged in user
    const { user } = useContext(UserContext)

    const dispatch = useDispatch()
    const history = useHistory()

    const params = useParams<RouteParams>()

    const [cube, setCube] = useState<ICube | null>(null)

    useEffect(() => {
        fetchCube()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(setPageName({ pageName: "Spots", pageUrl: "/admin/cubes/main", subPageName: cube ? cube.name : "" }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cube])

    async function fetchCube() {
        try {
            if (params && params.id) {
                const response = await axiosClient.get(`/spot/${params.id}`)
                const cube: ICube = response.data
                displayWarning(cube)
                setCube(cube)
            }
        } catch (error) {
            console.log("Failed to fetch Spots")
            history.push("/admin/404")
        }
    }

    function displayWarning(cube: ICube) {
        !cube.location && toast.warning(i18next.t("This Spot has not location"))
    }

    return (
        <div>
            {cube ? (
                <div>
                    <SubPageNavbar
                        routes={cubesRoutes.filter((route: ISubPageRoute) => {
                            return route.permission?.includes(user.role as ERoles)
                        })}
                        id={cube._id}
                    />
                    <Switch>
                        {cubesRoutes
                            .filter((route: ISubPageRoute) => {
                                return route.permission?.includes(user.role as ERoles)
                            })
                            .map((route: ISubPageRoute, key: number) => {
                                return (
                                    <Route
                                        key={key}
                                        path={route.path}
                                        exact
                                        render={() => {
                                            return <route.component cube={cube} setCube={setCube} />
                                        }}
                                    />
                                )
                            })}
                    </Switch>
                </div>
            ) : (
                <Loader />
            )}
        </div>
    )
}

export default CubeDetails
