import React from "react"
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { ISubPageRoute } from "../../interfaces/pages"

interface IProps {
    routes: ISubPageRoute[]
    id?: string
}

function SubPageNavbar(props: IProps) {
    const location = useLocation()
    const { t } = useTranslation();
    /**
     * get the status of the link (active or not)
     * @returns boolean
     */
    function isActive(routePath: string): boolean {
        if (!routePath) {
            return false
        }
        const path: string = location.pathname
        const UrlLastItem = path.substring(path.lastIndexOf("/") + 1)
        const LinkLastItem = routePath.substring(path.lastIndexOf("/") + 1)
        // if the last string of the url is the same as the last string of the link of the icon --> the icon is active
        return LinkLastItem === UrlLastItem
    }

    return (
        <div className="subpage-navbar">
            {props.routes.map((route) => {
                const path = props.id ? route.path.replace(":id", props.id) : route.path
                return (
                    <Link key={route.name} className={`tabitem ${isActive(path) ? "active" : ""}`} to={path}>
                        {t(route.name)}
                    </Link>
                )
            })}
        </div>
    )
}

export default SubPageNavbar
