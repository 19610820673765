//  Modules from the React eco-system
import React, { useState } from "react"
import { useHistory } from "react-router-dom"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"

// Own components
import RegionForm from "../forms/region.form"

// Axios
import axiosClient from "../../api/api"
import axios, { AxiosError } from "axios"

// Interfaces, enums
import { IRegion } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import { Button, Row, Col, OverlayTrigger, Popover } from "react-bootstrap"

interface IProps {
    region: IRegion
    setRegion: Function
}

function RegionUpdate(props: IProps) {
    const history = useHistory()

    // feedbacks
    const [deleteError, setDeleteError] = useState<string>("")

    /**
     * delete region from the backend
     */
    async function deleteRegion() {
        try {
            if (props.region && props.region.cubes.length) {
                toast.error("Region with Spot(s) can not be deleted")
                return
            }
            if (props.region) {
                await axiosClient.delete(`/region/${props.region._id}`)
                toast.success("The region is deleted successfully")
                history.push("/admin/regions/main")
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            } else {
                toast.error("Failed to delete the region")
            }
        }
    }

    /**
     * popover for the region deletion
     */
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Delete region</Popover.Header>
            <Popover.Body>
                <div>
                    <p>Delete this region?</p>
                    <Button className="btn orange" onClick={deleteRegion}>
                        Delete
                    </Button>
                </div>
            </Popover.Body>
        </Popover>
    )

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: props.region.name,
            description: props.region.description,
            image: undefined,
            location: [props.region.location ? props.region.location.coordinates[0] : 0, props.region.location ? props.region.location.coordinates[1] : 0],
        },
        validationSchema: Yup.object({
            name: Yup.string(),
            description: Yup.string(),
            image: Yup.mixed(),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                if (values.name || values.image) {
                    const formData = new FormData()

                    values.name && formik.values.name !== props.region.name && formData.append("name", values.name)
                    values.description && formik.values.description !== props.region.description && formData.append("description", values.description)
                    values.image && formData.append("type", "region")
                    values.image && formData.append("image", values.image)
                    values.location[0] && formData.append("lat", values.location[0].toString())
                    values.location[1] && formData.append("lng", values.location[1].toString())

                    const response = await axiosClient.patch(`/region/${props.region._id}`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    props.setRegion(response.data)
                    toast.success("The region is updated")
                    resetForm()
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    const err = error as AxiosError
                    toast.error(err.response?.data)
                } else {
                    toast.error("Failed to update the region")
                }
            }
        },
    })

    return (
        <>
            <Row>
                <Col>
                    <div className="tile">
                        <RegionForm formik={formik} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <OverlayTrigger trigger="click" placement="auto" overlay={popover} rootClose>
                        <Button className="orange">Delete Region</Button>
                    </OverlayTrigger>
                </Col>
            </Row>
        </>
    )
}

export default RegionUpdate
