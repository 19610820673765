//  Modules from the React eco-system
import { useMemo } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";

// Third-party modules
import moment from "moment"

// Own components
import TableDisplayer from "../table.component"
import { dateFilter, textFilter } from "../column-filter.component"

// Interfaces, enums
import { IRegionAdmin } from "../../interfaces/persons"

// Styles, bootstrap, icons
import Icon from "@mdi/react"
import {mdiTune, mdiCheck, mdiClose, mdiAccount} from "@mdi/js"
import { Row, Col } from "react-bootstrap"

interface IProps {
    regionAdmins: IRegionAdmin[]
    hiddenColumns?: string[]
}

function RegionAdminsTable(props: IProps) {
    const { t } = useTranslation();
    const columns = useMemo(
        () => [
            {
                Header: t("Details"),
                Cell: (data: any) => {
                    return (
                        <div>
                            <p>
                                <Link to={`/admin/regionadmins/${data.row.original._id}`}>
                                    <span className="icon-container btn orange">
                                        <Icon path={mdiTune} size={1} />
                                    </span>{" "}
                                </Link>

                                {data.value}
                            </p>
                        </div>
                    )
                },
                disableFilters: true,
            },
            {
                Header: t("Image"),
                accessor: "image",
                Cell: (data: any) => {
                    return (
                        <div>
                            {data.value ? (
                                <img className="table-image" src={data.value} alt="" />
                            ) : (
                                <div className="table-icon-container">
                                    <Icon path={mdiAccount} size={1} />
                                </div>
                            )}
                        </div>
                    )
                },
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("image"),
            },
            {
                Header: t("Name"),
                accessor: (data: IRegionAdmin): string => {
                    return `${data.firstname} ${data.lastname}`
                },
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("name"),
            },
            {
                Header: t("Region"),
                accessor: (data: any): string => {
                    return data.regions ? data.regions.map((region:any) => region.name).join(', ') : ''
                },
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("region"),
            },
            {
                Header: t("Email"),
                accessor: "email",
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("email"),
            },
            {
                Header: t("Company"),
                accessor: "company",
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("company"),
            },
            {
                Header: t("Tax Number"),
                accessor: "tax_number",
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("taxNumber"),
            },
            {
                Header: t("Phone"),
                accessor: "phone",
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("phone"),
            },
            {
                Header: "Id",
                accessor: "_id",
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("id"),
            },
            {
                Header: "Registered at",
                accessor: "createdAt",
                Cell: (data: any) => {
                    return <p>{moment(data.value).format("YYYY. MM. DD HH:mm")}</p>
                },
                Filter: dateFilter,
                isHidden: props.hiddenColumns?.includes("registeredAt"),
            },
            {
                Header: t("Email verified"),
                accessor: "isVerified",
                Cell: (data: any) => {
                    return data.value ? <Icon path={mdiCheck} size={1} /> : <Icon path={mdiClose} size={1} />
                },
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("emailVerified"),
            },
        ],

        [props.hiddenColumns]
    )

    return (
        <Row>
            <Col>
                <div className="table-tile">{props.regionAdmins && <TableDisplayer columns={columns} data={props.regionAdmins} />}</div>
            </Col>
        </Row>
    )
}

export default RegionAdminsTable
