//  Modules from the React eco-system
import React, { useState, useEffect } from "react"

// Third-party modules
import { FormikValues, useFormik } from "formik"
import * as Yup from "yup"
import Select from "react-select"
import { toast } from "react-toastify"

// Axios
import axiosClient from "../../api/api"
import { AxiosError } from "axios"

// Interfaces, enums
import {ICube, IRegion} from "../../interfaces/cubes"
import { ISupplier } from "../../interfaces/persons"

// Styles, bootstrap, icons
import { Form, Button, Row, Col } from "react-bootstrap"

interface IProps {
    setSuppliers: Function
}

const FormInitialValues = {
    firstname: "",
    lastname: "",
    email: "",
    username: "",
    password: "",
    regions: [],
    spots: [],
    company: "",
    phone: "",
    taxNumber: "",
}

function CreateSupplier(props: IProps) {
    const [regions, setRegions] = useState<IRegion[]>([])
    const [cubes, setCubes] = useState<ICube[]>([])

    useEffect(() => {
        fetchRegions()
        fetchCubes()
    }, [])

    async function fetchRegions() {
        try {
            const response = await axiosClient.get("/region?selectFields[]=name")
            setRegions(response.data)
        } catch (error) {
            toast.error("Failed to fetch regions")
        }
    }

    async function fetchCubes() {
        try {
            const response = await axiosClient.get("/spot/list")
            setCubes(response.data)
        } catch (error) {
            toast.error("Failed to fetch regions")
        }
    }

    const formik = useFormik({
        initialValues: FormInitialValues,
        validationSchema: Yup.object().shape({
            firstname: Yup.string().required("Required"),
            lastname: Yup.string().required("Required"),
            username: Yup.string().required("Required")
        }),
        onSubmit: async (values: FormikValues, actions: any) => {
            try {
                const supplierData = {
                    firstname: values.firstname,
                    lastname: values.lastname,
                    email: values.email,
                    username: values.username,
                    company: values.company,
                    password: values.password,
                    phone: values.phone,
                    tax_number: values.taxNumber,
                    regions: values.regions.map((reg: any) => reg.value),
                    spots: values.spots.map((cube: any) => cube.value),
                }
                const response = await axiosClient.post("/supplier", { supplierData: supplierData })
                actions.resetForm(FormInitialValues)
                if(supplierData.email){
                    toast.success("Invitation email sent")
                }else{
                    toast.success("Supplier successfully saved!")
                }

                props.setSuppliers((prev: ISupplier[]) => [...prev, response.data])
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    // TODO: proper type
    function getRegionOptions(): any[] {
        return regions.map((reg) => {
            return { value: reg._id, label: reg.name }
        })
    }

    function getCubeOptions(): any[] {
        return cubes.map((cube) => {
            return { value: cube._id, label: cube.name }
        })
    }

    return (
        <div>
            <Row>
                <Col>
                    <div className="tile">
                        {regions.length ? (
                            <Form onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col xs={12} sm={3}>
                                        <Form.Group className="mb-3" controlId="formSupplier">
                                            <Form.Label>Firstname:</Form.Label>
                                            <Form.Control
                                                onChange={formik.handleChange}
                                                name="firstname"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.firstname}
                                                type="text"
                                                placeholder="Firstname..."
                                            />
                                            {formik.touched.firstname && formik.errors.firstname ? <p className="input-error">{formik.errors.firstname}</p> : null}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={3}>
                                        <Form.Group className="mb-3" controlId="formSupplier">
                                            <Form.Label>Lastname:</Form.Label>
                                            <Form.Control
                                                onChange={formik.handleChange}
                                                name="lastname"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.lastname}
                                                type="text"
                                                placeholder="Lastname..."
                                            />
                                            {formik.touched.lastname && formik.errors.lastname ? <p className="input-error">{formik.errors.lastname}</p> : null}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={3}>
                                        <Form.Group className="mb-3" controlId="formSupplier">
                                            <Form.Label>Email:</Form.Label>
                                            <Form.Control onChange={formik.handleChange} name="email" onBlur={formik.handleBlur} value={formik.values.email} type="email" placeholder="Email..." />
                                            {formik.touched.email && formik.errors.email ? <p className="input-error">{formik.errors.email}</p> : null}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={3}>
                                        <Form.Group className="mb-3" controlId="formSupplier">
                                            <Form.Label>Username:</Form.Label>
                                            <Form.Control onChange={formik.handleChange} name="username" onBlur={formik.handleBlur} value={formik.values.username} type="text" placeholder="Username..." />
                                            {formik.touched.username && formik.errors.username ? <p className="input-error">{formik.errors.username}</p> : null}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={3}>
                                        <Form.Group className="mb-3" controlId="formSupplier">
                                            <Form.Label>Password:</Form.Label>
                                            <Form.Control onChange={formik.handleChange} name="password" onBlur={formik.handleBlur} value={formik.values.password} type="password" placeholder="Phone..." />
                                            {formik.touched.password && formik.errors.password ? <p className="input-error">{formik.errors.password}</p> : null}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={3}>
                                        <Form.Group className="mb-3" controlId="formSupplier">
                                            <Form.Label>Phone:</Form.Label>
                                            <Form.Control onChange={formik.handleChange} name="phone" onBlur={formik.handleBlur} value={formik.values.phone} type="text" placeholder="Phone..." />
                                            {formik.touched.phone && formik.errors.phone ? <p className="input-error">{formik.errors.phone}</p> : null}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={3}>
                                        <Form.Group className="mb-3" controlId="formSupplier">
                                            <Form.Label>Region:</Form.Label>
                                            {regions && (
                                                <Select isMulti options={getRegionOptions()} onChange={(t) => formik.setFieldValue("regions", [...t])} value={formik.values.regions} name="regions" />
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={3}>
                                        <Form.Group className="mb-3" controlId="formSupplier">
                                            <Form.Label>Spots:</Form.Label>
                                            {cubes && (
                                                <Select isMulti options={getCubeOptions()} onChange={(t) => formik.setFieldValue("spots", [...t])} value={formik.values.spots} name="spots" />
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={3}>
                                        <Form.Group className="mb-3" controlId="formSupplier">
                                            <Form.Label>Company:</Form.Label>
                                            <Form.Control onChange={formik.handleChange} name="company" onBlur={formik.handleBlur} value={formik.values.company} type="text" placeholder="Company..." />
                                            {formik.touched.company && formik.errors.company ? <p className="input-error">{formik.errors.company}</p> : null}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} sm={3}>
                                        <Form.Group className="mb-3" controlId="formSupplier">
                                            <Form.Label>Tax number:</Form.Label>
                                            <Form.Control
                                                onChange={formik.handleChange}
                                                name="taxNumber"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.taxNumber}
                                                type="text"
                                                placeholder="Tax number..."
                                            />
                                            {formik.touched.taxNumber && formik.errors.taxNumber ? <p className="input-error">{formik.errors.taxNumber}</p> : null}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Button className="orange" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        ) : (
                            <p>Create a region first</p>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default CreateSupplier
