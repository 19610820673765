//  Modules from the React eco-system
import { useState, useEffect } from "react"

// Third-party modules
import { toast } from "react-toastify"

// Own components
import SubPageNavbar from "../components/navigation/subpage-navbar.component"
import Loader from "../components/loader.component"

// Navigation
import { useParams } from "react-router"
import { Switch, Route } from "react-router-dom"
import organizerDetailsRoutes from "../routes/details/organizer-details.route"

// Redux
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"

// Axios
import axiosClient from "../api/api"

// Interfaces, enums
import { IOrganizer } from "../interfaces/persons"
import { ISubPageRoute } from "../interfaces/pages"

interface RouteParams {
    id: string
}

function OrganizerDetails() {
    const dispatch = useDispatch()

    const params = useParams<RouteParams>()

    const [organizer, setOrganizer] = useState<IOrganizer | null>(null)

    useEffect(() => {
        fetchRegion()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(setPageName({ pageName: "Organizers", pageUrl: "/admin/organizers/main", subPageName: organizer ? `${organizer.firstname} ${organizer.lastname}` : "" }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizer])

    async function fetchRegion() {
        try {
            if (params && params.id) {
                const response = await axiosClient.get(`/organizer/${params.id}`)
                const organizer: IOrganizer = response.data
                displayWarning(organizer)
                setOrganizer(organizer)
            }
        } catch (error) {
            toast.error("Failed to fetch Organizer")
        }
    }

    function displayWarning(organizer: IOrganizer) {

    }

    return (
        <div>
            {organizer ? (
                <div>
                    <SubPageNavbar routes={organizerDetailsRoutes} id={organizer._id} />

                    <Switch>
                        {organizerDetailsRoutes.map((route: ISubPageRoute, key: number) => {
                            return (
                                <Route
                                    key={key}
                                    path={route.path}
                                    exact
                                    render={() => {
                                        return (
                                            <div>
                                                <route.component organizer={organizer} setOrganizer={setOrganizer} />
                                            </div>
                                        )
                                    }}
                                />
                            )
                        })}
                    </Switch>
                </div>
            ) : (
                <Loader />
            )}
        </div>
    )
}

export default OrganizerDetails
