import { useMemo, useState } from "react"
import moment from "moment"
import { Link } from "react-router-dom"
import {IEvent} from "../../interfaces/cubes"
import { IUser } from "../../interfaces/persons"
import TableDisplayer from "../table.component"
import { textFilter, dateFilter, selectColumnFilter } from "../column-filter.component"
import Icon from "@mdi/react"
import { mdiTune } from "@mdi/js"
import {apiUrl} from "../../config/config";
import {useTranslation} from "react-i18next";

interface IProps {
    events: IEvent[]
    spotId?: string
    hiddenColumns?: string[]
}

// TODO: proper types (Cell data)
function EventsTable(props: IProps) {
    const { t } = useTranslation();
    const [urlFilter, setUrlFilter] = useState([]);

    function filterDates(rows:any, id:any, filterValue:any) {
        setUrlFilter(filterValue);
        return rows.filter((row:any) => {
            let filterFrom = filterValue[0];
            let filterTo = filterValue[1];
            const rowValue = row.values[id]
            if(filterFrom && filterFrom.split(' ').length === 1){
                filterFrom += ' 00:00:00'
            }
            if(filterTo && filterTo.split(' ').length === 1){
                filterTo += ' 23:59:59'
            }
            return moment(rowValue).isBetween(filterFrom, filterTo)
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: t("_"),
                Cell: (data: any) => {
                    return (
                        <div>
                            <p>
                                <Link to={`/admin/event/${data.row.original._id}`}>
                                    <span className="icon-container btn orange">
                                        <Icon path={mdiTune} size={1} />
                                    </span>{" "}
                                </Link>

                                {data.value}
                            </p>
                        </div>
                    )
                },
                disableFilters: true,
            },
            {
                Header: t("Created At"),
                accessor: "createdAt",
                Cell: (data: any) => {
                    return <>
                        <p>{moment(data.value).format("YYYY. MM. DD HH:mm")}</p>
                        <p style={{fontSize: '10px'}}>{moment(data.row.original.startedAt).format("YYYY. MM. DD HH:mm")} - {moment(data.row.original.finishedAt).format("YYYY. MM. DD HH:mm")}</p>
                    </>
                },
                Filter: dateFilter,
                filter: filterDates,
                isHidden: props.hiddenColumns?.includes("createdAt"),
            },
            {
                Header: t("User"),
                accessor: (data: IEvent) => {
                    return `${(data.user as IUser).firstname} ${(data.user as IUser).lastname}`
                },
                Cell: (data: any) => {
                    return <Link to={`/admin/users/${data.row.original.user._id}`}>{data.value}</Link>
                },
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("user"),
            },
            {
                Header: t("Spot"),
                accessor: "spot.name",
                Cell: (data: any) => {
                    return <Link to={`/admin/cubes/${data.row.original.spot._id}`}>{data.value}</Link>
                },
                Filter: selectColumnFilter,
                isHidden: props.hiddenColumns?.includes("cube"),
            },
            {
                Header: "Id",
                accessor: "_id",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("id"),
            },
            {
                Header: t("Action"),
                accessor: "types",
                Cell: (data: any) => {
                    return data.value.join(', ')
                },
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("types"),
            },
            {
                Header: t("Supplier Note"),
                accessor: "user_note",
                Cell: (data: any) => {
                    return data.value ? data.value : '-'
                },
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("user_note"),
            },
            {
                Header: t("Supervisor Note"),
                accessor: "supervised_note",
                Cell: (data: any) => {
                    return data.value ? data.value : '-'
                },
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("supervised_note"),
            },
            {
                Header: t("Status"),
                accessor: "status",
                Filter: selectColumnFilter,
                isHidden: props.hiddenColumns?.includes("status"),
            },
        ],
        [props.hiddenColumns]
    )
    const exportUrl = `${apiUrl}/event/pdf?spotId=${props.spotId}&dateFrom=${urlFilter[0] || 0}&dateTo=${urlFilter[1] || 0}`;
    return <div>{props.events && <>
                <a href={exportUrl}>{t('Download PDF')}</a>
                <TableDisplayer columns={columns} data={props.events} />
            </>
        }</div>
}

export default EventsTable
