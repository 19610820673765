import { useState } from "react"
import { ICube, IEvent } from "../../interfaces/cubes"
import moment from "moment"
import Select from "react-select"
import axiosClient from "../../api/api"
import Icon from "@mdi/react"
import { mdiCalendarBlankOutline, mdiChevronDown, mdiChevronUp } from "@mdi/js"
import { EEventStatus } from "../../constants/enum"
import { toast } from "react-toastify"

interface IProps {
    event: IEvent
    setEvent: Function
}
interface IStatusOption {
    value: EEventStatus
    label: EEventStatus
}
// options for the status selector
const statusOptions: IStatusOption[] = [
    { value: EEventStatus.PENDING, label: EEventStatus.PENDING },
    { value: EEventStatus.ERROR, label: EEventStatus.ERROR },
    { value: EEventStatus.VERIFIED, label: EEventStatus.VERIFIED },
    { value: EEventStatus.STARTED, label: EEventStatus.STARTED },
    { value: EEventStatus.COMPLETED, label: EEventStatus.COMPLETED }
]

/**
 * component for the main infos of one event
 * @param props
 * @returns
 */
function EventMainInfos(props: IProps) {
    // show/hide status selector
    const [showStatusSelector, setShowStatusSelector] = useState<boolean>(false)

    // change the status of the event
    async function changeStatus(status: EEventStatus) {
        try {
            const response = await axiosClient.patch(`/event/${props.event._id}`, { updateData: { status: status } })
            props.setEvent(response.data)
            toast.success("Event status is updated")
        } catch (error) {
            toast.success("Failed to change status")
        }
    }

    return (
        <div className="tile reservation-maininfos">
            <div className="orange-icon-container">
                <Icon path={mdiCalendarBlankOutline} size={1} />
            </div>

            <p>{`${moment(props.event.createdAt).format("YYYY. MMM. DD.")}`}</p>
            <h2>{(props.event.spot as ICube).name}</h2>
            <div className="status-container">
                <div className="status-info">
                    <p className={`status ${props.event.status}`}>{props.event.status}</p>
                    <span className="status-chevron-container" onClick={() => setShowStatusSelector(!showStatusSelector)}>
                        <Icon path={showStatusSelector ? mdiChevronUp : mdiChevronDown} size={1} />
                    </span>
                </div>

                {showStatusSelector && (
                    <Select
                        className="select-status"
                        options={statusOptions}
                        onChange={(option) => option && changeStatus(option.value as EEventStatus)}
                        value={{ value: props.event.status, label: props.event.status }}
                    />
                )}
            </div>

            <p>Created: {moment(props.event.createdAt).format("YYYY. MMM. DD.")}</p>
        </div>
    )
}

export default EventMainInfos
