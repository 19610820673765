//  Modules from the React eco-system
import React, { useState, useEffect, useContext } from "react"
import { Link, useHistory } from "react-router-dom"
import { useParams } from "react-router"

// Third-party modules
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { toast } from "react-toastify"
import moment from "moment"

// Own components
import EventMainInfos from "../components/events/event-maininfos.component"
import UserInfo from "../components/users/user-info.component"
import CubeInfos from "../components/cubes/cube-maininfos.component"
import CustomGoogleMap from "../components/google-map.component"

// Axios
import axiosClient from "../api/api"
import axios, { AxiosError } from "axios"

// Redux, store
import { useDispatch } from "react-redux"
import { setPageName } from "../store/pageSlice"
import { UserContext } from "../store/UserProvider"

// Interfaces, enums
import { IEvent, ICube } from "../interfaces/cubes"
import { IUser } from "../interfaces/persons"
import { ERoles } from "../constants/enum"

// Styles, bootstrap, icons
import { Row, Col, Popover, Button, OverlayTrigger } from "react-bootstrap"
import Loader from "../components/loader.component"
import { mdiLockClock, mdiWaterPump, mdiCreditCardOutline } from "@mdi/js"
import Icon from "@mdi/react"

ChartJS.register(ArcElement, Tooltip, Legend)

interface RouteParams {
    id: string
}

/**
 * page of one event
 */
function EventDetails() {
    //Logged in user
    const { user } = useContext(UserContext)

    const dispatch = useDispatch()
    const params = useParams<RouteParams>()
    const history = useHistory()

    // event from the fetch
    const [event, setEvent] = useState<IEvent | null>(null)

    useEffect(() => {
        // set page name
        dispatch(setPageName({ pageName: "Events", pageUrl: "/admin/events", subPageName: event ? event._id : "" }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event])

    useEffect(() => {
        // fetch regions
        fetchEvents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * fetch the event by the id from the params
     */
    async function fetchEvents() {
        try {
            if (params && params.id) {
                const response = await axiosClient.get(`/event/${params.id}`)
                const event: IEvent = response.data
                setEvent(event)
            }
        } catch (error) {
            toast.error("Failed to fetch event")
            history.push("/admin/404")
        }
    }

    function getMarkerData() {
        if (event) {
            return [
                {
                    position: { lat: (event.spot as ICube).location.coordinates[0], lng: (event.spot as ICube).location.coordinates[1] },
                    infoWindow: (
                        <div className="infoWindow">
                            <Link to={`/admin/cubes/${(event.spot as ICube)._id}`}>{(event.spot as ICube).name}</Link>
                        </div>
                    ),
                },
            ]
        } else {
            return []
        }
    }

    /**
     * popover for the delete button
     */
    const deletePopover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Delete Event</Popover.Header>
            <Popover.Body>
                <div>
                    <p>Delete this event?</p>
                    <Button className="btn orange" onClick={deleteEvent}>
                        Delete
                    </Button>
                </div>
            </Popover.Body>
        </Popover>
    )

    /**
     * delete event from the backend
     */
    async function deleteEvent() {
        try {
            if (event) {
                await axiosClient.delete(`/event/${event._id}`)
                toast.success("Event is deleted")
                history.push("/admin/events")
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            } else {
                toast.error("Failed to delete event")
            }
        }
    }

    return (
        <div className="reservation-details-page">
            {event ? (
                <div>
                    <Row>
                        <Col xs={12} md={6}>
                            <EventMainInfos event={event} setEvent={setEvent} />
                        </Col>

                        <Col xs={12} md={6}>
                            <div className="simple-tile map-tile">
                                <CustomGoogleMap
                                    zoom={7}
                                    center={{ lat: (event.spot as ICube).location.coordinates[0], lng: (event.spot as ICube).location.coordinates[1] }}
                                    markerDatas={getMarkerData()}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <UserInfo user={event.user as IUser} />
                        </Col>
                        <Col>
                            <CubeInfos cube={event.spot as ICube} />
                        </Col>
                    </Row>
                    {user.role === ERoles.SUPERADMIN ? (
                        <Row>
                            <Col>
                                <OverlayTrigger trigger="click" placement="auto" overlay={deletePopover} rootClose>
                                    <Button className="orange">Delete Event</Button>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <Loader />
            )}
        </div>
    )
}

export default EventDetails
