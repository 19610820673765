import CreateUser from "../../components/users/user-create.component"
import UsersTable from "../../components/users/users-table.component"
import { ERoles } from "../../constants/enum"
import { ISubPageRoute } from "../../interfaces/pages"

const usersMainRoutes: ISubPageRoute[] = [
    {
        path: "/admin/users/main",
        name: "Infos",
        component: UsersTable,
        props: {
            hiddenColumns: ["email", "numberOfEvents", "id", "gender", "birthdate", "country", "phone", "registeredAt"],
        },
        permission: [],
    },
    {
        path: "/admin/users/main/update",
        name: "Create User",
        component: CreateUser,
        permission: [ERoles.SUPERADMIN],
    },
]

export default usersMainRoutes
