import { Button, Form, Modal, Row } from "react-bootstrap";
import { ICalendarEvent } from "./cube-calendar.component";
import { IUser } from "../../interfaces/persons";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosClient from "../../api/api";
import DatePicker from "react-datepicker";
import { ICube, IScheduledEvent } from "../../interfaces/cubes";
import moment from "moment";

interface IProps {
    show: boolean
    setShow: Function
    events: ICalendarEvent[]
    setEvents: Function
    selectedDate: Date
    spot: ICube
    scheduledEvents: IScheduledEvent[]
    setScheduledEvents: Function
}

export enum EScheduleOption {
    NONE = "Nincs",
    DAILY = "Naponta",
    WEEKLY = "Hetente",
    MONTHLY = "Havonta",
    YEARLY = "Évente"
}

const repeatOptions = Object.values(EScheduleOption).map(value => value);

function ScheduleEventModal(props: IProps) {
    const [users, setUsers] = useState<IUser[]>([]);
    const [eventData, setEventData] = useState<IScheduledEvent>({
        title: "",
        user: "",
        spot: props.spot._id,
        region: props.spot.region._id,
        startDate: props.selectedDate || new Date(),
        repeat: EScheduleOption.NONE,
    })

    useEffect(() => {
        fetchUsers();
    }, [])

    const fetchUsers = async () => {
        try {
            const response = await axiosClient.get("/supplier")
            setUsers(response.data)
        } catch (error) {
            toast.error("Failed to fetch users")
        }
    }

    const closeModal = () => {
        props.setShow(false);
        setEventData({
            title: "",
            user: "",
            spot: props.spot._id,
            region: props.spot.region._id,
            startDate: new Date(),
            repeat: EScheduleOption.NONE,
        });
    }

    const handleSubmit = async(e:any) => {
        try {
            e.preventDefault();
            await axiosClient.post("/scheduled-event/", eventData);
            updateEvents();
            closeModal();
            toast.success("Esemény beütemezve!");
        } catch (error) {
            console.log(error);
            toast.error("Esemény beütemezés sikertelen!");
        }
    }

    const updateEvents = () => {
        let updatedEvents = [...props.events];
        const eventUser:IUser|undefined = typeof eventData.user === "string" ? users.find(user => user._id === eventData.user) : eventData.user;
        const title = `${eventData.title} - ${eventUser?.firstname} ${eventUser?.lastname}`
        switch (eventData.repeat) {
            case EScheduleOption.NONE:
                updatedEvents.push({
                    start: eventData.startDate,
                    end: eventData.endDate || eventData.startDate,
                    title: title,
                    id: updatedEvents.length,
                    allDay: true
                });
                break;
            case EScheduleOption.DAILY:
                const days = eventData.endDate ? moment(eventData.endDate).diff(eventData.startDate, "days") : 1000;
                for (let i = 0; i <= days+1; i++) {
                    updatedEvents.push({
                        start: moment(eventData.startDate).add(i, "days").toDate(),
                        end: moment(eventData.startDate).add(i, "days").toDate(),
                        title: title,
                        id: updatedEvents.length,
                        allDay: true
                    });
                }
                break;
            case EScheduleOption.WEEKLY:
                const weeks = eventData.endDate ? (moment(eventData.endDate).diff(eventData.startDate, "days") + 1) / 7 : 1000;
                for (let i = 0; i <= weeks; i++) {
                    updatedEvents.push({
                        start: moment(eventData.startDate).add(i, "weeks").toDate(),
                        end: moment(eventData.startDate).add(i, "weeks").toDate(),
                        title: title,
                        id: updatedEvents.length,
                        allDay: true
                    });
                }
                break;
            case EScheduleOption.MONTHLY:
                const months = eventData.endDate ? (moment(eventData.endDate).diff(eventData.startDate, "days") + 1) / 30 : 1000;
                for (let i = 0; i <= months; i++) {
                    updatedEvents.push({
                        start: moment(eventData.startDate).add(i, "months").toDate(),
                        end: moment(eventData.startDate).add(i, "months").toDate(),
                        title: title,
                        id: updatedEvents.length,
                        allDay: true
                    });
                }
                break;
            case EScheduleOption.YEARLY:
                const years = eventData.endDate ? (moment(eventData.endDate).diff(eventData.startDate, "days") + 1) / 365 : 1000;
                for (let i = 0; i <= years; i++) {
                    updatedEvents.push({
                        start: moment(eventData.startDate).add(i, "years").toDate(),
                        end: moment(eventData.startDate).add(i, "years").toDate(),
                        title: title,
                        id: updatedEvents.length,
                        allDay: true
                    });
                }
                break;
        }
        props.setEvents(updatedEvents);

        const updatedScheduledEvents = [...props.scheduledEvents];
        updatedScheduledEvents.push({...eventData, user: eventUser || ""});
        props.setScheduledEvents(updatedScheduledEvents);
    }

    return (
        <Modal className="schedule-event-modal" show={props.show} centered >
            <Modal.Header>
                <Modal.Title>Esemény ütemezése</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Esemény</Form.Label>
                        <Form.Control required value={eventData.title} onChange={(e) => setEventData({...eventData, title: e.target.value})}  />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Felhasználó</Form.Label>
                        <Form.Select required value={typeof eventData.user === "string" ? eventData.user : eventData.user._id} onChange={(e) => setEventData({...eventData, user: e.target.value})}>
                            <option value="">-</option>
                            {users.map(user => {
                                return <option value={user._id}>{`${user.firstname} ${user.lastname}`}</option>
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <Form.Label>Kezdő dátum</Form.Label>
                        <Row><DatePicker showYearDropdown required value={moment(eventData.startDate).format("YYYY MM DD")} onChange={(value) => setEventData({...eventData, startDate: value || new Date()})}/></Row>
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <Form.Label>Vég. dátum</Form.Label>
                        <Row><DatePicker showYearDropdown value={eventData.endDate ? moment(eventData.endDate).format("YYYY MM DD") : undefined} onChange={(value) => setEventData({...eventData, endDate: value || undefined})}/></Row>
                    </Form.Group>
                    <Form.Group className="mt-3 mb-1">
                        <Form.Label>Ismétlődés</Form.Label>
                            <div>
                                {repeatOptions.map(option => {
                                    return <Button className="m-2" onClick={() => setEventData({...eventData, repeat: option})} variant={eventData.repeat === option ? "secondary" : "outline-secondary"}>{option}</Button>
                                })}
                            </div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" className="btn orange">Mentés</Button>
                    <Button className="btn" variant="secondary" onClick={closeModal}>Bezárás</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
export default ScheduleEventModal;