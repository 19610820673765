import OrganizerDetailsHome from "../../components/organizers/organizer-details-home.component"
import OrganizerUpdate from "../../components/organizers/organizer-update.component"
import { ISubPageRoute } from "../../interfaces/pages"

const organizerDetailsRoutes: ISubPageRoute[] = [
    {
        path: "/admin/organizer/:id",
        name: "Details",
        component: OrganizerDetailsHome,
    },
    {
        path: "/admin/organizer/:id/update",
        name: "Edit",
        component: OrganizerUpdate,
    },
]

export default organizerDetailsRoutes
