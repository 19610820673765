import { useParams } from "react-router-dom"

/**
 * Temporary page for the user's password reset
 * It will be on the barbecube desktop page
 * TODO: automatically open the mobile app
 */

function PasswordResetPage() {
    const { token }: { token: string } = useParams()

    const isMobileDevice = /Mobi/i.test(window.navigator.userAgent)

    return (
        <div>
            {token ? (
                <div className={`btn ${isMobileDevice ? "orange" : ""}`} style={{ height: "70px", width: "200px", margin: "50px auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {isMobileDevice ?<a style={{ textDecoration: "none" }} href={`com.spotmonitor.app://change-password/${token}`}>
                        Spotmonitor App megnyitása
                    </a> : <p>Nyisd meg a linket a mobilodon</p>}
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default PasswordResetPage
