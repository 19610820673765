import React from "react"
import { Row, Col } from "react-bootstrap"
import { IRegion } from "../../interfaces/cubes"
import { ISupplier } from "../../interfaces/persons"
import AdminInfo from "../admin-info.component"

interface IProps {
    region: IRegion
}

function RegionSuppliers(props: IProps) {
    return (
        <Row className="region-suppliers">
            <Col>
                <div className="region-suppliers-container">
                    {props.region.suppliers.length ? (
                        (props.region.suppliers as ISupplier[]).map((supplier) => {
                            return (
                                <div className="supplier">
                                    <AdminInfo admin={supplier} />
                                </div>
                            )
                        })
                    ) : (
                        <p>There are no cleaners in this region.</p>
                    )}
                </div>
            </Col>
        </Row>
    )
}

export default RegionSuppliers
