//  Modules from the React eco-system
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"

// Own components
import EventsTable from "../components/events/event-table.component"
import Loader from "../components/loader.component"

// Axios
import axiosClient from "../api/api"

// Redux
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"

// Interfaces, enums
import { IEvent } from "../interfaces/cubes"

function Events() {
    const dispatch = useDispatch()
    const history = useHistory()

    const [events, setEvents] = useState<IEvent[]>([])

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        dispatch(setPageName({ pageName: "Events", pageUrl: "/admin/events", subPageName: "" }))

        fetchEvents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchEvents() {
        setIsLoading(true)
        try {
            const response = await axiosClient.get("/event/list")
            setEvents(response.data)
        } catch (error) {
            console.log("Failed to fetch events")
            history.push("/admin/404")
        }
        setIsLoading(false)
    }

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="table-tile">
                        <EventsTable events={events} hiddenColumns={["spot", "id", "status", "createdAt"]} />
                    </div>
                </>
            )}
        </div>
    )
}

export default Events
