import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    lng: 'hu',
    supportedLngs: ['hu','en'],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    resources: {
      en: {
        translation: {

        }
      },
      hu: {
        translation: {
            // Main routes
            "Dashboard": 'Vezérlőpult',
            "Regions": 'Régiók',
            "Region Admins": 'Régió Adminok',
            "Spots": 'Spotok',
            "Events": 'Események',
            "Operators": 'Üzemeltetők',
            "Organizers": 'Diszpécserek',
            "Users": 'Felhasználók',
            "Administrators": 'Adminisztrátorok',
            "Suppliers": 'Karbantartók',
            "Send Email": "Email küldés",

            // Login
            "Login": "Belépés",
            "Enter your email and password below": "Kérjük adja meg email címét és jelszavát a belépéshez",
            "Email": "Email",
            "Password": "Jelszó",
            "Password Again": "Jelszó megerősítés",
            "Forgot password": "Elfelejtett jelszó",
            "Log In": "Belépés",
            "Log out": "Kilépés",
            "Password is a required field": "Jelszó kötelező",
            "Passwords must match": "A jelszavaknak egyezniük kell",
            "Failed to verify the account": "Fiók megerősítése sikertelen",
            "Verify Account": "Fiók megerősítés",
            "Successful verification": "Sikeres megerősítés",

            // Profile
            "Profile": "Profil",
            "You have not image": "Nincs profilkép feltöltve",
            "You have not phone number": "Nincs telefonszám kitöltve",
            "Verified Account": "Megerősített Felhasználó",
            "Account is not verified": "Felhasználónincs megerősítve",
            "Invite": "Meghívás",
            'Supplier successfully saved!': 'Karbantartó sikeresen létrehozva!',

            // Spot
            "This Spot has not location": "Ennek a Spotnak hiányzik a lokációja!",
            "Details": "Tulajdonságok",
            "Edit": "Szerkesztés",
            "Calendar": "Naptár",
            "Sensors": "Szenzorok",
            "Infos": "Lista",
            "Create Spot": "Spot Hozzáadása",

            // Region
            "Create Region": "Régió Hozzáadása",

            "Create User": "Felhasználó Hozzáadása",
            "Create Supplier": "Karbantartó Hozzáadása",
            "Create Operator": "Üzemeltető Hozzáadása",
            "Create Organizer": "Dispécser Hozzáadása",
            "Create Admin": "Adminisztrátor Hozzáadása",
            "Supplier Note": "Karbantartó Megjegyzése",
            "Supervisor Note": "Ellenőr Megjegyzése",
            "Action": "Elvégzett Feladat",
            "Started At": "Elkezdve",
            "Finished At": "Befejezve",
            "Created At": "Létrehozva",
            "Download PDF": "Napló Letöltése",
            "User": "Felhasználó",
            "Status": "Státusz",


            // Grid
            "Name": "Név",
            "Image": "Kép",
            "Short description": "Rövid Leírás",
            "Company": "Cég",
            "Tax Number": "Adószám",
            "Phone": "Telefonszám",
            "Email verified": "Megerősítve"



        }
      },
    }
  });

export default i18n;





