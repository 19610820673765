//  Modules from the React eco-system
import React, { useState, useEffect } from "react"

// Axios
import axiosClient from "../../api/api"

// Own components
import AdminInfo from "../admin-info.component"
import ActivityLogs from "../dashboard/activity-logs.component"

// Interfaces, enums
import { ISupplier } from "../../interfaces/persons"
import { IActivityLog } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import { Row, Col } from "react-bootstrap"

interface IProps {
    supplier: ISupplier
}

function SupplierDetailsHome(props: IProps) {
    const [logs, setLogs] = useState<IActivityLog[]>([])

    useEffect(() => {
        fetchLogs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchLogs() {
        try {
            const response = await axiosClient.get(`/log?actor=${props.supplier._id}`)
            setLogs(response.data)
        } catch (error) {
            console.log("Failed to fetch logs")
        }
    }
    return (
        <div>
            <Row>
                <Col xs={12} md={12}>
                    <AdminInfo admin={props.supplier} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="table-tile">
                        <ActivityLogs logs={logs} />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default SupplierDetailsHome
