//  Modules from the React eco-system
import { useState, useEffect } from "react"

// Own components
import SubPageNavbar from "../components/navigation/subpage-navbar.component"
import Loader from "../components/loader.component"

// Navigation
import regionAdminsMainRoutes from "../routes/mains/regionadmins-main.route"
import { Route, Switch, useHistory } from "react-router-dom"

// Axios
import axiosClient from "../api/api"

// Redux
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"

// Interfaces, enums
import { IRegionAdmin } from "../interfaces/persons"
import { ISubPageRoute } from "../interfaces/pages"

function RegionAdmins() {
    const dispatch = useDispatch()
    const history = useHistory()

    const [regionAdmins, setRegionAdmins] = useState<IRegionAdmin[]>([])

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        dispatch(setPageName({ pageName: "Region Admins", pageUrl: "/admin/regionadmins/main", subPageName: "" }))
        fetchRegionAdmins()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function fetchRegionAdmins() {
        setIsLoading(true)
        try {
            const response = await axiosClient.get("/regionadmin")
            setRegionAdmins(response.data)
        } catch (error) {
            console.log("Failed to fetch region admins")
            history.push("/admin/404")
        }
        setIsLoading(false)
    }

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <SubPageNavbar routes={regionAdminsMainRoutes} />

                    <Switch>
                        {regionAdminsMainRoutes.map((route: ISubPageRoute, key: number) => {
                            return (
                                <Route
                                    key={key}
                                    path={route.path}
                                    exact
                                    render={() => {
                                        return (
                                            <div>
                                                <route.component regionAdmins={regionAdmins} setRegionAdmins={setRegionAdmins} {...route.props} />
                                            </div>
                                        )
                                    }}
                                />
                            )
                        })}
                    </Switch>
                </>
            )}
        </div>
    )
}

export default RegionAdmins
