//  Modules from the React eco-system
import { useMemo } from "react"
import { Link } from "react-router-dom"

// Third-party modules
import moment from "moment"

// Own components
import { dateFilter, textFilter } from "../column-filter.component"
import TableDisplayer from "../table.component"

// Interfaces, enums
import { IRegion } from "../../interfaces/cubes"
import {IOperator, ISupplier, IRegionAdmin} from "../../interfaces/persons"

// Styles, bootstrap, icons
import Icon from "@mdi/react"
import {mdiTune, mdiCheck, mdiClose, mdiCubeOutline, mdiFaceManProfile, mdiAccount} from "@mdi/js"
import { Row, Col } from "react-bootstrap"
import {useTranslation} from "react-i18next";

interface IProps {
    suppliers: ISupplier[]
    hiddenColumns?: string[]
}

function SuppliersTable(props: IProps) {
    const { t } = useTranslation();
    const columns = useMemo(
        () => [
            {
                Header: t("Details"),
                Cell: (data: any) => {
                    return (
                        <div>
                            <p>
                                <Link to={`/admin/suppliers/${data.row.original._id}`}>
                                    <span className="icon-container btn orange">
                                        <Icon path={mdiTune} size={1} />
                                    </span>{" "}
                                </Link>

                                {data.value}
                            </p>
                        </div>
                    )
                },
                disableFilters: true,
            },
            {
                Header: t("Image"),
                accessor: "image",
                Cell: (data: any) => {
                    return (
                        <div>
                            {data.value ? (
                                <img className="table-image" src={data.value} alt="" />
                            ) : (
                                <div className="table-icon-container">
                                    <Icon path={mdiAccount} size={1} />
                                </div>
                            )}
                        </div>
                    )
                },
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("image"),
            },
            {
                Header: t("Name"),
                accessor: (data: ISupplier): string => {
                    return `${data.firstname} ${data.lastname}`
                },
                Cell: (data: any) => {
                    return (
                        <div>
                            <Link to={`/admin/suppliers/${data.row.original._id}`}>{data.value}</Link>
                        </div>
                    )
                },
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("name"),
            },
            {
                Header: t("Email"),
                accessor: "email",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("email"),
            },
            {
                Header: ("Phone"),
                accessor: "phone",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("phone"),
            },
            {
                Header: ("Tax number"),
                accessor: "tax_number",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("taxNumber"),
            },
            {
                Header: t("Company"),
                accessor: "company",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("company"),
            },
            {
                Header: t("Regions"),
                accessor: (data: any) => {
                    const regions: string = (data.regions as IRegion[])
                        .map((region) => {
                            return region.name
                        })
                        .join(", ")

                    return regions
                },
                Cell: (data: any) => {
                    return (
                        <>
                            {(data.row.original.regions as IRegion[]).map((region) => {
                                return (
                                    <span>
                                        <Link to={`/admin/regions/${region._id}`}>{region.name}</Link>
                                        {", "}
                                    </span>
                                )
                            })}
                        </>
                    )
                },
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("regions"),
            },
            {
                Header: "Registered at",
                accessor: "createdAt",
                Cell: (data: any) => {
                    return <p>{moment(data.value).format("YYYY. MM. DD HH:mm")}</p>
                },
                Filter: dateFilter,
                isHidden: props.hiddenColumns?.includes("registeredAt"),
            },
            {
                Header: t("Email verified"),
                accessor: "isVerified",
                Cell: (data: any) => {
                    return data.value ? <Icon path={mdiCheck} size={1} /> : <Icon path={mdiClose} size={1} />
                },
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("emailVerified"),
            },
        ],

        [props.hiddenColumns]
    )

    return (
        <Row>
            <Col>
                <div className="table-tile">{props.suppliers && <TableDisplayer columns={columns} data={props.suppliers} />}</div>
            </Col>
        </Row>
    )
}

export default SuppliersTable
