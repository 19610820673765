//  Modules from the React eco-system
import { useState, useEffect, useContext } from "react"
import { useHistory } from "react-router-dom"

// Third-party modules
import { toast } from "react-toastify"

// Own components
import SubPageNavbar from "../components/navigation/subpage-navbar.component"

// Redux
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"
import { UserContext } from "../store/UserProvider"

// Axios
import axiosClient from "../api/api"

// Navigation
import { Route, Switch, useParams } from "react-router"
import regionRoutes from "../routes/details/region-details.route"

// Interfaces, enums
import { IRegion } from "../interfaces/cubes"
import { ISubPageRoute } from "../interfaces/pages"
import Loader from "../components/loader.component"
import { ERoles } from "../constants/enum"

interface RouteParams {
    id: string
}

/**
 * details page of one region
 * @returns
 */
function RegionDetails() {
    //Logged in user
    const { user } = useContext(UserContext)

    // dispatch for the redux
    const dispatch = useDispatch()
    const history = useHistory()

    const params = useParams<RouteParams>()

    // the region from the fetch
    const [region, setRegion] = useState<IRegion | null>(null)

    useEffect(() => {
        //fetch region
        fetchRegion()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(setPageName({ pageName: "Regions", pageUrl: "/admin/regions/main", subPageName: region ? region.name : "" }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [region])

    /**
     * fetch the region (by the id from the params)
     */
    async function fetchRegion() {
        try {
            if (params && params.id) {
                const response = await axiosClient.get(`/region/${params.id}`)
                const region: IRegion = response.data
                displayWarning(region)
                setRegion(region)
            }
        } catch (error) {
            console.log("Failed to fetch regions")
            history.push("/admin/404")
        }
    }

    function displayWarning(region: IRegion) {
        !region.description && toast.warning("This region has not description")
        !region.location && toast.warning("This region has not location")
        !region.name && toast.warning("This region has not name")
        !region.operators && toast.warning("This region has not operator")
    }

    return (
        <div>
            {region ? (
                <div>
                    <SubPageNavbar
                        routes={regionRoutes.filter((route: ISubPageRoute) => {
                            return route.permission?.includes(user.role as ERoles)
                        })}
                        id={region._id}
                    />
                    <Switch>
                        {regionRoutes
                            .filter((route: ISubPageRoute) => {
                                return route.permission?.includes(user.role as ERoles)
                            })
                            .map((route: ISubPageRoute, key: number) => {
                                return (
                                    <Route
                                        key={key}
                                        path={route.path}
                                        exact
                                        render={() => {
                                            return <route.component region={region} setRegion={setRegion} />
                                        }}
                                    />
                                )
                            })}
                    </Switch>
                </div>
            ) : (
                <Loader />
            )}
        </div>
    )
}

export default RegionDetails
