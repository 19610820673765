//  Modules from the React eco-system

// Third party modules
import moment from "moment"

// Own components
import ChartEvents from "../../components/charts/chart-events.component"
import RadarChart from "../../components/charts/radar-chart.component"
import PieChart from "../../components/charts/pie-chart.component"

// Interfaces, enums
import {ICube, IEvent} from "../../interfaces/cubes"
import { IData } from "../../pages/dashboard.page"

// Styles, bootstrap, icons
import { Col, Row } from "react-bootstrap"

interface IProps {
    data: IData
}

function DashboardCharts(props: IProps) {
    /**
     * create data for reservation chart
     * @param reservations
     * @returns ChartData
     */
    function createEventChartData() {
        // get reservations of the current year
        const eventsInThisYear = props.data.events.filter((evt: IEvent) => moment(new Date()).isSame(evt.createdAt, "year"))
        // create array filled with zeros
        const datesByMonths = new Array(12).fill(0)
        // count the reservations by month
        eventsInThisYear.forEach((evt: IEvent) => {
            const monthOfRes = new Date(evt.createdAt).getMonth()
            datesByMonths[monthOfRes]++
        })
        // create array of months as labels of the chart
        const months = Array.from({ length: 12 }, (item, i) => {
            return new Date(0, i).toLocaleString("en-US", { month: "long" })
        })

        return {
            labels: months,
            datasets: [
                {
                    label: "Issues reported in this year",
                    backgroundColor: "#008aad",
                    borderWidth: 0,
                    fill: false,
                    data: datesByMonths,
                },
            ],
        }
    }

    /**
     * create data for radar chart about the reservations of the cubes
     * @returns ChartData
     */
    function createRadarChartData() {
        let cubeCounterObj: any = {}
        // create object for the count of reservations of cubes
        props.data.cubes.forEach((cube) => {
            cubeCounterObj[cube.name] = 0
        })
        // get reservations of the current year
        const eventsInThisYear = props.data.events.filter((evt) => moment(new Date()).isSame(evt.createdAt, "year"))
        // count the reservations and add it to the cube
        eventsInThisYear.forEach((res) => {
            cubeCounterObj[(res.spot as ICube).name]++
        })

        return {
            labels: Object.keys(cubeCounterObj),
            datasets: [
                {
                    label: "Issues reported by spot this year",
                    data: Object.values(cubeCounterObj),
                    backgroundColor: "#f2542c28",
                    borderColor: "#f2552c",
                    borderWidth: 2,
                },
            ],
        }
    }

    /**
     * create data for pie chart about the activity of the users
     * @returns ChartData
     */
    function createPieChartData() {
        // get the 5 most active users
        const mostActiveUsers = props.data.users
            .sort(function (aUser, bUser) {
                return bUser.events.length - aUser.events.length
            })
            .slice(0, 5)
        let i = 1;    
        return {
            labels: mostActiveUsers.map((user) => `Takarító ${i}`),
            datasets: [
                {
                    label: "Most active users",
                    data: mostActiveUsers.map((user) => user.events.length),
                    backgroundColor: ["#f2552c", "#00a66e", "#008aad", "#fb8b23", "#00a66e", "#cd212a", "#7c7c7c"],
                    borderWidth: 1,
                },
            ],
        }
    }
    return (
        <div className="dashboard-charts">
            <Row>
                <Col xs={12}>
                    <div className="tile reservation-chart-container">
                        <ChartEvents data={createEventChartData()} />
                    </div>
                </Col>
            </Row>
            {/* <Row className="radar-pie-row">
                <Col xs={12} md={6}>
                    <div className="tile radar">
                        <RadarChart data={createRadarChartData()} />
                    </div>
                </Col>
                <Col xs={12} md={6}>
                    <div className="tile pie">
                        <PieChart data={createPieChartData()} />
                    </div>
                </Col>
            </Row> */}
        </div>
    )
}

export default DashboardCharts
