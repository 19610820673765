export enum ERoles {
    SUPERADMIN = "SuperAdmin", // full admin access
    REGIONADMIN = "RegionAdmin", // access to selected region only
    OPERATOR = "Operator", // contractor access to selected list of regions and spots
    SUPERVISOR = "Supervisor", // inside supervisor
    EXTERNAL_SUPERVISOR = "ExternalSupervisor",
    SUPPLIER = "Supplier", // Cleaner
    USER = "User",
    ORGANIZER = "Organizer",
}

export enum EEventStatus {
    PENDING = "pending",
    VERIFIED = "verified",
    ERROR = "error",
    STARTED = "started",
    COMPLETED = "completed",
}

export enum EActivityAction {
    POST = "post",
    CREATE = "create",
    UPDATE = "update",
    DELETE = "delete",
    LOGIN = "login",
}
