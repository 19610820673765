//  Modules from the React eco-system
import { useState, useEffect } from "react"

// Third-party modules
import { toast } from "react-toastify"

// Own components
import SubPageNavbar from "../components/navigation/subpage-navbar.component"
import Loader from "../components/loader.component"

// Navigation
import { useParams } from "react-router"
import { Switch, Route } from "react-router-dom"
import regionAdminDetailsRoutes from "../routes/details/regionadmin-details.route"

// Redux
import { setPageName } from "../store/pageSlice"
import { useDispatch } from "react-redux"

// Axios
import axiosClient from "../api/api"

// Interfaces, enums
import { IRegionAdmin } from "../interfaces/persons"
import { ISubPageRoute } from "../interfaces/pages"

interface RouteParams {
    id: string
}

function RegionAdminDetails() {
    const dispatch = useDispatch()

    const params = useParams<RouteParams>()

    const [regionAdmin, setRegionAdmin] = useState<IRegionAdmin | null>(null)

    useEffect(() => {
        fetchRegion()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(setPageName({ pageName: "Region Admins", pageUrl: "/admin/regionadmins/main", subPageName: regionAdmin ? `${regionAdmin.firstname} ${regionAdmin.lastname}` : "" }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regionAdmin])

    async function fetchRegion() {
        try {
            if (params && params.id) {
                const response = await axiosClient.get(`/regionadmin/${params.id}`)
                const regionAdmin: IRegionAdmin = response.data
                displayWarning(regionAdmin)
                setRegionAdmin(regionAdmin)
            }
        } catch (error) {
            toast.error("Failed to fetch Region Admin")
        }
    }

    function displayWarning(regionAdmin: IRegionAdmin) {

    }

    return (
        <div>
            {regionAdmin ? (
                <div>
                    <SubPageNavbar routes={regionAdminDetailsRoutes} id={regionAdmin._id} />

                    <Switch>
                        {regionAdminDetailsRoutes.map((route: ISubPageRoute, key: number) => {
                            return (
                                <Route
                                    key={key}
                                    path={route.path}
                                    exact
                                    render={() => {
                                        return (
                                            <div>
                                                <route.component regionAdmin={regionAdmin} setRegionAdmin={setRegionAdmin} />
                                            </div>
                                        )
                                    }}
                                />
                            )
                        })}
                    </Switch>
                </div>
            ) : (
                <Loader />
            )}
        </div>
    )
}

export default RegionAdminDetails
