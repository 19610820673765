//  Modules from the React eco-system
import React, { useState, useEffect } from "react"

// Third-party modules
import { useFormik } from "formik"
import * as Yup from "yup"
import Select from "react-select"
import { toast } from "react-toastify"

// Own components
import PhotoUploader from "../profil-image-uploader.component"

// Axios
import { AxiosError } from "axios"
import axiosClient from "../../api/api"

// Interfaces, enums
import { IOrganizer } from "../../interfaces/persons"
import { IRegion } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import { Col, Row, Form, Button } from "react-bootstrap"
import { useHistory } from "react-router-dom"

// Images
import default_avatar from "../../assets/img/default-avatar.jpg"

interface IProps {
    organizer: IOrganizer
    setOrganizer: Function
}

function OrganizerUpdate(props: IProps) {
    const history = useHistory()

    const [showImageUploader, setShowImageUploader] = useState(false)

    const [regions, setRegions] = useState<IRegion[]>([])

    useEffect(() => {
        fetchRegions()
    }, [])

    async function fetchRegions() {
        try {
            const response = await axiosClient.get("/region?selectFields[]=name")
            setRegions(response.data)
        } catch (error) {
            toast.error("Failed to fetch regions")
        }
    }

    function createOptionsFromRegions() {
        if (props.organizer.regions && regions.length) {
            return props.organizer.regions.map((region:any) => {
                return { label: region.name, value: region._id };
            })
        } else {
            return [{ label: "", value: "" }]
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstname: props.organizer.firstname,
            lastname: props.organizer.lastname,
            email: props.organizer.email,
            phone: props.organizer.phone,
            regions: regions
                .filter((reg) => {
                    return props.organizer.regions.find((el) => el._id === reg._id)
                })
                .map((filteredReg) => {
                    return { value: filteredReg._id, label: filteredReg.name }
                }),
            company: props.organizer.company,
            taxNumber: props.organizer.tax_number,
        },
        validationSchema: Yup.object().shape({
            firstname: Yup.string(),
            lastname: Yup.string(),
            email: Yup.string().email("Email is incorrect"),
        }),
        onSubmit: async (values, actions) => {
            try {
                if (props.organizer) {
                    const updateData = {
                        ...(values.firstname && values.firstname !== props.organizer.firstname && { firstname: values.firstname }),
                        ...(values.lastname && values.lastname !== props.organizer.lastname && { lastname: values.lastname }),
                        ...(values.email && values.email !== props.organizer.email && { email: values.email }),
                        ...(values.phone && values.phone !== props.organizer.phone && { phone: values.phone }),
                        ...(values.regions.map((reg) => reg.value).join() !== props.organizer.regions.map((reg) => reg._id).join() && { regions: values.regions.map((reg) => reg.value) }),
                        ...(values.company && values.company !== props.organizer.company && { company: values.company }),
                        ...(values.taxNumber && values.taxNumber !== props.organizer.tax_number && { tax_number: values.taxNumber }),
                    }
                    if (Object.keys(updateData).length) {
                        const response = await axiosClient.patch(`/organizer/${props.organizer._id}`, updateData)
                        toast.success("Region Admin is updated")
                        props.setOrganizer(response.data)
                        history.push(`/admin/organizers/${props.organizer._id}`)
                    }
                }
            } catch (error) {
                const err = error as AxiosError
                toast.error(err.response?.data)
            }
        },
    })

    // TODO: proper type
    function getOptions(): any[] {
        return regions.map((reg) => {
            return { value: reg._id, label: reg.name }
        })
    }

    return (
        <div className="operator-update">
            <div className="tile edit-container">
                <Row>
                    <Col xs={12} sm={2} className="avatar-container">
                        <img className="user-picture" alt="profile-avatar" src={props.organizer.image ? props.organizer.image : default_avatar} />

                        <Button onClick={() => setShowImageUploader(true)} className="orange">
                            Upload image
                        </Button>
                    </Col>
                    <Col>
                        <Form onSubmit={formik.handleSubmit}>
                            <Row>
                                <Col xs={12} sm={3}>
                                    <Form.Group className="mb-3" controlId="formSupplier">
                                        <Form.Label>Firstname:</Form.Label>
                                        <Form.Control
                                            onChange={formik.handleChange}
                                            name="firstname"
                                            onBlur={formik.handleBlur}
                                            value={formik.values.firstname}
                                            type="text"
                                            placeholder="Firstname..."
                                        />
                                        {formik.touched.firstname && formik.errors.firstname ? <p className="input-error">{formik.errors.firstname}</p> : null}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={3}>
                                    <Form.Group className="mb-3" controlId="formSupplier">
                                        <Form.Label>Lastname:</Form.Label>
                                        <Form.Control onChange={formik.handleChange} name="lastname" onBlur={formik.handleBlur} value={formik.values.lastname} type="text" placeholder="Lastname..." />
                                        {formik.touched.lastname && formik.errors.lastname ? <p className="input-error">{formik.errors.lastname}</p> : null}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={3}>
                                    <Form.Group className="mb-3" controlId="formSupplier">
                                        <Form.Label>Email:</Form.Label>
                                        <Form.Control onChange={formik.handleChange} name="email" onBlur={formik.handleBlur} value={formik.values.email} type="email" placeholder="Email..." />
                                        {formik.touched.email && formik.errors.email ? <p className="input-error">{formik.errors.email}</p> : null}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={3}>
                                    <Form.Group className="mb-3" controlId="formSupplier">
                                        <Form.Label>Telefonszám:</Form.Label>
                                        <Form.Control onChange={formik.handleChange} name="phone" onBlur={formik.handleBlur} value={formik.values.phone} type="text" placeholder="Telefonszám..." />
                                        {formik.touched.phone && formik.errors.phone ? <p className="input-error">{formik.errors.phone}</p> : null}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={4}>
                                    <Form.Group className="mb-3" controlId="formSupplier">
                                        <Form.Label>Regions</Form.Label>
                                        {regions && <Select isMulti options={getOptions()} onChange={(t) => formik.setFieldValue("regions", [...t])} value={formik.values.regions} name="regions" />}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <Form.Group className="mb-3" controlId="formSupplier">
                                        <Form.Label>Company:</Form.Label>
                                        <Form.Control onChange={formik.handleChange} name="company" onBlur={formik.handleBlur} value={formik.values.company} type="text" placeholder="Company..." />
                                        {formik.touched.company && formik.errors.company ? <p className="input-error">{formik.errors.company}</p> : null}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={4}>
                                    <Form.Group className="mb-3" controlId="formSupplier">
                                        <Form.Label>Adószám:</Form.Label>
                                        <Form.Control onChange={formik.handleChange} name="taxNumber" onBlur={formik.handleBlur} value={formik.values.taxNumber} type="text" placeholder="Adószám..." />
                                        {formik.touched.taxNumber && formik.errors.taxNumber ? <p className="input-error">{formik.errors.taxNumber}</p> : null}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Button className="orange" type="submit">
                                Update
                            </Button>
                        </Form>
                    </Col>
                </Row>

                <PhotoUploader
                    show={showImageUploader}
                    handleClose={() => setShowImageUploader(false)}
                    setPerson={(imageUrl: string) => props.setOrganizer({ ...props.organizer, image: imageUrl })}
                    personId={props.organizer._id}
                    role={props.organizer.role}
                />
            </div>
        </div>
    )
}

export default OrganizerUpdate
