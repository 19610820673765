//  Modules from the React eco-system

// Own components
import CubesTable from "../cubes/cubes-table.component"
import CustomGoogleMap from "../google-map.component"

// Interfaces, enums
import { ICube, IRegion } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

interface IProps {
    region: IRegion
}

function RegionCubes(props: IProps) {
    function getMarkerData() {
        const markerData = (props.region.cubes as ICube[]).map((cube) => {
            return {
                position: { lat: cube.location.coordinates[0], lng: cube.location.coordinates[1] },
                infoWindow: (
                    <div className="infoWindow">
                        <Link to={`/admin/cubes/${cube._id}`}>{cube.name}</Link>
                    </div>
                ),
            }
        })
        return markerData
    }
    return (

                    <CubesTable cubes={props.region.cubes as ICube[]} hiddenColumns={["id", "createdAt", "region", "short_description"]} />

    )
}

export default RegionCubes
