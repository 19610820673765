//  Modules from the React eco-system
import React from "react"
import { Link } from "react-router-dom"

// Own components
import RegionInfo from "./region-info.component"
import CustomGoogleMap from "../google-map.component"

// Interfaces, enums
import { IRegion, ICube } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import { Row, Col } from "react-bootstrap"

interface IProps {
    region: IRegion
}
/**
 * region details page, details component
 * @param props
 * @returns
 */
function RegionHome(props: IProps) {
    function getMarkerData() {
        const markerData = (props.region.cubes as ICube[]).map((cube) => {
            return {
                position: { lat: cube.location.coordinates[0], lng: cube.location.coordinates[1] },
                infoWindow: (
                    <div className="infoWindow">
                        <Link to={`/admin/cubes/${cube._id}`}>{cube.name}</Link>
                    </div>
                ),
            }
        })
        return markerData
    }

    return (
        <div className="region-details">
            <Row>
                <Col xs={12} md={4}>
                    <RegionInfo region={props.region} />
                </Col>

                <Col xs={12} md={8}>
                    <div className="simple-tile map-tile">
                        <CustomGoogleMap
                            zoom={parseInt(process.env.REACT_APP_REGION_MAP_ZOOM_LEVEL || "7")}
                            center={
                                props.region.cubes.length
                                    ? { lat: (props.region.cubes as ICube[])[0].location.coordinates[0], lng: (props.region.cubes as ICube[])[0].location.coordinates[1] }
                                    : { lat: 47.1803791, lng: 19.5045089 }
                            }
                            markerDatas={getMarkerData()}
                        />
                    </div>
                </Col>
            </Row>
            {props.region.description ? (
                <Row>
                    <Col>
                        <div className="tile">
                            <p>{props.region.description}</p>
                        </div>
                    </Col>
                </Row>
            ) : (
                <></>
            )}
        </div>
    )
}

export default RegionHome
