import React from "react"
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment"
import "react-big-calendar/lib/css/react-big-calendar.css"
import { EventTypes } from "../interfaces/cubes"

interface IProps {
    events: any
    onSelectSlotFunction: any
    handleSelectEvent: any
}

const localizer = momentLocalizer(moment)

function EventCalendar(props: IProps) {
    function eventStyleGetter(event: any, start: any, end: any, isSelected: any) {
        const backgroundColor = event.type === EventTypes.CLEANING ? "#f2552c" : "#969696"
        return {
            style: {
                backgroundColor: backgroundColor,
            },
        }
    }

    return (
        <div>
            <Calendar
                localizer={localizer}
                popup
                onSelectEvent={props.handleSelectEvent}
                views={["month", "agenda"]}
                events={props.events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                eventPropGetter={eventStyleGetter}
                selectable
                onSelectSlot={(slot) => {
                    props.onSelectSlotFunction(slot.start)
                }}
            />
        </div>
    )
}

export default EventCalendar
