import moment from "moment"
import Icon from "@mdi/react"
import { mdiMagnify } from "@mdi/js"
import { apiUrl } from "../config/config";

// TODO: props type
export function textFilter({ column }: any) {
    const { filterValue, setFilter } = column

    return (
        <span className="text-filter-container">
            <input className="search-input" value={filterValue || ""} onChange={(e) => setFilter(e.target.value)} />
            {!filterValue && <Icon className="search-icon" path={mdiMagnify} size={1.2} />}
        </span>
    )
}

// TODO: props type
export function dateFilter({ column }: any) {
    const { filterValue, setFilter } = column
    return (
        <>
            <span>
                <input type="date" value={filterValue && filterValue[0] ? filterValue[0] : undefined} onChange={(e) => setFilter((old: any = []) => [e.target.value, old[1] ? old[1] : undefined])} />
                <input type="date" value={filterValue && filterValue[1]} onChange={(e) => setFilter((old: any = []) => [old[0] ? old[0] : undefined, e.target.value])} />
            </span>
        </>
    )
}

export function dateRangeFilter(dataArr: any[], columnIds: any, filterValue: any) {
    if (!filterValue) {
        return dataArr
    }
    const filteredData = dataArr.filter((data) => {
        if (filterValue[0] && filterValue[1]) {
            return moment(data.original.startDate).isBetween(filterValue[0], filterValue[1], "day", "[]")
        } else if (filterValue[0]) {
            return moment(data.original.startDate).isSameOrAfter(filterValue[0], "day")
        } else if (filterValue[1]) {
            return moment(data.original.startDate).isSameOrBefore(filterValue[1], "day")
        } else {
            return true
        }
    })
    return filteredData
}

export function selectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: any) {
    const options = new Set()
    preFilteredRows.forEach((row: any) => {
        options.add(row.values[id])
    })

    const optArr = Array.from(options.values())

    return (
        <span className="select-filter-container">
            <select
                value={filterValue}
                onChange={(e) => {
                    setFilter(e.target.value || undefined)
                }}>
                <option value="">All</option>
                {(optArr as string[]).map((option, i) => (
                    <option key={i} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </span>
    )
}
