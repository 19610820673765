// Modules from the React eco-system
import React, { useState } from "react"

// Own components
import AdminInfo from "../admin-info.component"
import CubeInfos from "./cube-maininfos.component"
import CubeImageUploader from "./cube-image-uploader-modal.component"
import CustomGoogleMap from "../google-map.component"

// Interfaces, enums
import { ICube } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import { Col, Row, Button } from "react-bootstrap"

interface IProps {
    cube: ICube
    setCube: Function
}

function CubeHome(props: IProps) {
    // show image uploader
    const [showUploader, setShowUploader] = useState<boolean>(false)

    function getMarkerData() {
        return [
            {
                position: { lat: props.cube.location.coordinates[0], lng: props.cube.location.coordinates[1] },
                infoWindow: (
                    <div className="infoWindow">
                        <p>{props.cube.name}</p>
                    </div>
                ),
            },
        ]
    }

    return (
        <div className="cube-details-home">
            <Row>
                <Col xs={12} md={4}>
                    <CubeInfos cube={props.cube} />
                </Col>
                <Col xs={12} md={4}>
                    <div className="simple-tile map-tile">
                        <CustomGoogleMap zoom={12} center={{ lat: props.cube.location.coordinates[0], lng: props.cube.location.coordinates[1] }} markerDatas={getMarkerData()} />
                    </div>
                </Col>
                <Col xs={12} md={4 / props.cube.region.operators.length}>
                    {props.cube.region.operators ? props.cube.region.operators.map((operator:any) => (<AdminInfo admin={operator} />)) : <p>The region has no operator</p>}
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={12}>
                    <div className="tile cube-image-tile">
                        {props.cube.images ? (
                            props.cube.images.map((item, index) => (
                                <div key={`img-${index}`} className="cube-image-container">
                                    <img src={item} alt="cube" className={index === 0 ? "first" : ""} />
                                </div>
                            ))
                        ) : (
                            <Button className="btn orange" onClick={() => setShowUploader(true)}>
                                Upload image
                            </Button>
                        )}
                    </div>
                </Col>
            </Row>
            <div className="divider"></div>
            <CubeImageUploader show={showUploader} handleClose={() => setShowUploader(false)} cubeid={props.cube._id} setCube={props.setCube} />
        </div>
    )
}

export default CubeHome
