import React, { useContext } from "react"
import { useTranslation } from "react-i18next";
import { Nav, Navbar, Button } from "react-bootstrap"
import Icon from "@mdi/react"
import { mdiLogoutVariant, mdiDotsVertical } from "@mdi/js"
import { IBaseAdmin } from "../../interfaces/persons"
import logout from "../../helper/logout"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { UserContext } from "../../store/UserProvider"

// Images
import default_avatar from "../../assets/img/default-avatar.jpg"

interface IProps {
    setsidebarToggleClass: Function
}

function NavbarTop(props: IProps) {
    //Logged in user
    const admin: IBaseAdmin = useContext(UserContext).user
    const { t } = useTranslation();

    const { pageName, pageUrl, subPageName } = useSelector((state) => (state as RootState).pageReducer)

    return (
        <Navbar className="navbar">
            <span className="toggle" onClick={() => props.setsidebarToggleClass("show")}>
                <Icon path={mdiDotsVertical} size={1} />
            </span>

            <div className="pagename">
                {subPageName ? <Link to={pageUrl}>{t(pageName)}</Link> : <span>{t(pageName)}</span>}
                <span>{subPageName ? ` / ${t(subPageName)}` : ""}</span>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="">
                    <Navbar.Text className="user">
                        <Link to="/admin/profile">
                            {`${admin.firstname} ${admin.lastname}`}
                            <div className="avatar">
                                <img className="user-picture" alt="profile-avatar" src={admin.image ? admin.image : default_avatar} />
                            </div>
                        </Link>
                    </Navbar.Text>
                    <Button className=" btn orange logout" onClick={logout}>
                        <span>
                            <Icon path={mdiLogoutVariant} size={1} />
                        </span>
                        <span className="logout-text">{t('Log out')}</span>
                    </Button>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default NavbarTop
