import CreateRegionAdmin from "../../components/regionadmins/regionadmin-create.component"
import RegionAdminsTable from "../../components/regionadmins/regionadmin-table.component"
import { ISubPageRoute } from "../../interfaces/pages"

const regionAdminsMainRoutes: ISubPageRoute[] = [
    {
        path: "/admin/regionadmins/main",
        name: "Infos",
        component: RegionAdminsTable,
        props: {
            hiddenColumns: ["email", "company", "taxNumber", "phone", "id", "registeredAt", "emailVerified"],
        },
    },
    {
        path: "/admin/regionadmins/main/create",
        name: "Create Region Admin",
        component: CreateRegionAdmin,
    },
]

export default regionAdminsMainRoutes
