//  Modules from the React eco-system
import React, { useMemo } from "react"

// Own components
import TableDisplayer from "../table.component"

// Interfaces, enums
import {ISuperAdmin, ISupplier} from "../../interfaces/persons"

// Styles, bootstrap, icons
import { Row, Col } from "react-bootstrap"
import {useTranslation} from "react-i18next";
import Icon from "@mdi/react";
import {mdiAccount} from "@mdi/js";

interface IProps {
    admins: ISuperAdmin[]
    hiddenColumns?: string[]
}

function AdminsTable(props: IProps) {
    const { t } = useTranslation();
    const columns = useMemo(
        () => [
            {
                Header: t("Image"),
                accessor: "image",
                Cell: (data: any) => {
                    return (
                        <div>
                            {data.value ? (
                                <img className="table-image" src={data.value} alt="" />
                            ) : (
                                <div className="table-icon-container">
                                    <Icon path={mdiAccount} size={1} />
                                </div>
                            )}
                        </div>
                    )
                },
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("image"),
            },
            {
                Header: t("Name"),
                accessor: (data: ISuperAdmin): string => {
                    return `${data.firstname} ${data.lastname}`
                },
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("name"),
            },
            {
                Header: t("Email"),
                accessor: "email",
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("email"),
            },
        ],

        [props.hiddenColumns]
    )

    return (
        <Row>
            <Col>
                <div className="table-tile">{props.admins && <TableDisplayer columns={columns} data={props.admins} />}</div>{" "}
            </Col>
        </Row>
    )
}

export default AdminsTable
