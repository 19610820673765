import CreateOrganizer from "../../components/organizers/organizer-create.component"
import OrganizersTable from "../../components/organizers/organizer-table.component"
import { ISubPageRoute } from "../../interfaces/pages"

const organizersMainRoutes: ISubPageRoute[] = [
    {
        path: "/admin/organizers/main",
        name: "Infos",
        component: OrganizersTable,
        props: {
            hiddenColumns: ["email", "company", "taxNumber", "phone", "id", "registeredAt", "emailVerified"],
        },
    },
    {
        path: "/admin/organizers/main/create",
        name: "Create Organizer",
        component: CreateOrganizer,
    },
]

export default organizersMainRoutes
