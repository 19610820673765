//  Modules from the React eco-system
import { useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { Column } from "react-table"

// Own components
import { dateFilter, selectColumnFilter, textFilter } from "../column-filter.component"
import TableDisplayer from "../table.component"
import CustomGoogleMap from "../google-map.component"

// Interfaces, enums
import { ICube } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import Icon from "@mdi/react"
import {mdiTune, mdiCubeOutline, mdiWarehouse, mdiHomeVariant} from "@mdi/js"
import { Row, Col, Button, ButtonGroup } from "react-bootstrap"
import moment from "moment"

interface IProps {
    cubes: ICube[]
    hiddenColumns?: string[]
}

function CubesTable(props: IProps) {
    // display modes
    const [showTable, setShowTable] = useState(true)
    const [showMap, setShowMap] = useState(true)

    const { t } = useTranslation();

    const columns: Column<any>[] = useMemo(
        () => [
            {
                Header: t("Details"),
                Cell: (data: any) => {
                    return (
                        <div>
                            <p>
                                <Link to={`/admin/cubes/${data.row.original._id}`}>
                                    <span className="icon-container btn orange">
                                        <Icon path={mdiTune} size={1} />
                                    </span>{" "}
                                </Link>

                                {data.value}
                            </p>
                        </div>
                    )
                },
                disableFilters: true,
            },
            {
                Header: t("Image"),
                accessor: "images",
                Cell: (data: any) => {
                    return (
                        <div>
                            {data.value ? (
                                <img className="table-image" src={data.value[0]} alt="" />
                            ) : (
                                <div className="table-icon-container">
                                    <Icon path={mdiHomeVariant} size={1} />
                                </div>
                            )}
                        </div>
                    )
                },
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("image"),
            },
            {
                Header: t("Name"),
                accessor: "name",
                Cell: (data) => {
                    return <Link to={`/admin/cubes/${data.row.original._id}`}>{data.value}</Link>
                },
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("name"),
            },

            {
                Header: "Id",
                accessor: "_id",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("id"),
            },
            {
                Header: t("Created At"),
                accessor: "createdAt",
                Cell: (data: any) => {
                    return <p>{moment(data.value).format("YYYY. MM. DD HH:mm")}</p>
                },
                Filter: dateFilter,
                isHidden: props.hiddenColumns?.includes("createdAt"),
            },
            {
                Header: t("Region"),
                accessor: "region.name",
                Filter: selectColumnFilter,
                isHidden: props.hiddenColumns?.includes("region"),
            },
            {
                Header: t("Short description"),
                accessor: "short_description",
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("short_description"),
            },
        ],
        [props.hiddenColumns]
    )

    function getMarkerData() {
        const markerData = (props.cubes as ICube[]).map((cube) => {
            return {
                position: { lat: cube.location.coordinates[0], lng: cube.location.coordinates[1] },
                infoWindow: (
                    <div className="infoWindow">
                        <Link to={`/admin/cubes/${cube._id}`}>{cube.name}</Link>
                    </div>
                ),
            }
        })
        return markerData
    }

    return (
        <div className="cubes-table">
            <Row>
                <Col className="display-selector-container">
                    <ButtonGroup>
                        <Button
                            className="btn orange"
                            onClick={() => {
                                setShowTable(true)
                                setShowMap(true)
                            }}>
                            Table + Map
                        </Button>
                        <Button
                            className="btn orange"
                            onClick={() => {
                                setShowTable(true)
                                setShowMap(false)
                            }}>
                            Table
                        </Button>
                        <Button
                            className="btn orange"
                            onClick={() => {
                                setShowTable(false)
                                setShowMap(true)
                            }}>
                            Map
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                {showTable ? (
                    <Col>
                        <div className="table-tile">{props.cubes && <TableDisplayer columns={columns} data={props.cubes} />}</div>
                    </Col>
                ) : (
                    <></>
                )}
                {showMap ? (
                    <Col>
                        <div className="simple-tile map-tile">
                            <CustomGoogleMap
                                zoom={parseInt(process.env.REACT_APP_SPOT_MAP_ZOOM_LEVEL || "12")}
                                center={
                                    props.cubes.length
                                        ? { lat: (props.cubes as ICube[])[0].location.coordinates[0], lng: (props.cubes as ICube[])[0].location.coordinates[1] }
                                        : { lat: 47.1803791, lng: 19.5045089 }
                                }
                                markerDatas={getMarkerData()}
                            />
                        </div>
                    </Col>
                ) : (
                    <></>
                )}
            </Row>
        </div>
    )
}

export default CubesTable
