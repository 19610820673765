import CreateCube from "../../components/cubes/cubes-create-form.component"
import CubesTable from "../../components/cubes/cubes-table.component"
import {ISubPageRoute} from "../../interfaces/pages"
import {ERoles} from "../../constants/enum"

const cubesMainRoutes: ISubPageRoute[] = [
    {
        path: "/admin/cubes/main",
        name: "Infos",
        component: CubesTable,
        props: {
            hiddenColumns: ["id", "createdAt", "region", "short_description"],
        },
        permission: [ERoles.SUPERADMIN, ERoles.OPERATOR, ERoles.SUPPLIER, ERoles.REGIONADMIN, ERoles.ORGANIZER],
    },
    {
        path: "/admin/cubes/main/update",
        name: "Create Spot",
        component: CreateCube,
        permission: [ERoles.SUPERADMIN],
    },
]

export default cubesMainRoutes
