import { EActivityAction } from "../constants/enum"
import { IOperator, ISupplier, IUser, IBaseAdmin, IRegionAdmin } from "./persons"

export interface IRegion {
    _id: string
    name: string
    description: string
    operators: string[] | IOperator[]
    cubes: string[] | ICube[]
    suppliers: string[] | ISupplier[]
    createdAt: Date
    image: string
    location?: {
        type: string
        coordinates: number[]
    }
}

export interface ICube {
    // assets: {}
    _id: string
    name: string
    region: IRegion
    description: string
    short_description: string
    side: string,
    threshold: number,
    
    events: string | IEvent[]
    scheduledEvents: IScheduledEvent[]
    images: string[]
    location: {
        type: string
        coordinates: number[]
    }
    // Home assistant infos
    mqtt_name: string
    home_assistant_ip: string
}

export interface IEvent {
    _id: string
    user: string | IUser
    spot: string | ICube
    region: string | IRegion
    status: "completed" | "verified" | "error" | "pending" | "started"
    createdAt: Date,
    updatedAt: Date,
    signature: string,
    supervisor: string | IUser,
    supervisedAt: Date,
    supervised_note: string,
    supervised_signature: string,
    types: []
}

export interface IScheduledEvent {
    _id?: string
    user: string | IUser
    title: string
    spot: string | ICube
    region: string | IRegion
    repeat: string
    startDate: Date
    endDate?: Date
    createdAt?: Date
    updatedAt?: Date
}

export enum EventTypes {
    CLEANING = "cleaning",
    MAINTENANCE = "maintenance",
}

export interface IActivityLog {
    actor: IBaseAdmin
    entity: any
    data: any
    entityType: string
    action: EActivityAction
    description: string
}
