//  Modules from the React eco-system
import React, { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router"

// Third-party modules
import moment from "moment"

// Own components
import ConfirmModal from "../confirm-modal.component"
import EventCalendar from "../event-calendar.component"

// Axios
import axiosClient from "../../api/api"

// Interfaces, enums
import { EventTypes, ICube, IEvent, IScheduledEvent } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import { Button, Col, Row } from "react-bootstrap"
import { startCase } from "lodash"
import ScheduleEventModal, { EScheduleOption } from "./schedule-event-modal.component"
import { IUser } from "../../interfaces/persons"
import { mdiTune, mdiHomeVariant } from "@mdi/js"
import Icon from "@mdi/react"
import { t } from "i18next"
import { Link } from "react-router-dom"
import { Column } from "react-table"
import { textFilter, dateFilter, selectColumnFilter } from "../column-filter.component"
import TableDisplayer from "../table.component"

interface IProps {
    cube: ICube
    setCube: Function
}

export interface ICalendarEvent {
    start: Date
    end: Date
    allDay: boolean
    title: string
    id: number
}

function CubeCalendar(props: IProps) {
    const history = useHistory()

    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false);
    const [events, setEvents] = useState<ICalendarEvent[]>([]);
    const [scheduledEvents, setScheduledEvents] = useState<IScheduledEvent[]>(props.cube.scheduledEvents || []);

    useEffect(() => {
        createEvents();
    }, [])

    function createEvents() {
        const mergedEvents: ICalendarEvent[] = [];
        if (props.cube.events) {
            (props.cube.events as IEvent[]).forEach((evt, idx) => {
                mergedEvents.push({
                    start: evt.createdAt,
                    end: evt.createdAt,
                    allDay: true,
                    title: startCase(EventTypes.CLEANING),
                    id: idx
                })
            })
        }

        if (props.cube.scheduledEvents) {
            props.cube.scheduledEvents.forEach((eventData, idx) => {
                switch (eventData.repeat) {
                    case EScheduleOption.NONE:
                        mergedEvents.push({
                            start: eventData.startDate,
                            end: eventData.endDate || eventData.startDate,
                            title: `${eventData.title} - ${(eventData.user as IUser).firstname} ${(eventData.user as IUser).lastname}`,
                            id: mergedEvents.length,
                            allDay: true
                        });
                        break;
                    case EScheduleOption.DAILY:
                        const days = eventData.endDate ? moment(eventData.endDate).diff(eventData.startDate, "days") : 1000;
                        for (let i = 0; i <= days + 1; i++) {
                            mergedEvents.push({
                                start: moment(eventData.startDate).add(i, "days").toDate(),
                                end: moment(eventData.startDate).add(i, "days").toDate(),
                                title: `${eventData.title} - ${(eventData.user as IUser).firstname} ${(eventData.user as IUser).lastname}`,
                                id: mergedEvents.length,
                                allDay: true
                            });
                        }
                        break;
                    case EScheduleOption.WEEKLY:
                        const weeks = eventData.endDate ? (moment(eventData.endDate).diff(eventData.startDate, "days") + 1) / 7 : 1000;
                        for (let i = 0; i <= weeks; i++) {
                            mergedEvents.push({
                                start: moment(eventData.startDate).add(i, "weeks").toDate(),
                                end: moment(eventData.startDate).add(i, "weeks").toDate(),
                                title: `${eventData.title} - ${(eventData.user as IUser).firstname} ${(eventData.user as IUser).lastname}`,
                                id: mergedEvents.length,
                                allDay: true
                            });
                        }
                        break;
                    case EScheduleOption.MONTHLY:
                        const months = eventData.endDate ? (moment(eventData.endDate).diff(eventData.startDate, "days") + 1) / 30 : 1000;
                        for (let i = 0; i <= months; i++) {
                            mergedEvents.push({
                                start: moment(eventData.startDate).add(i, "months").toDate(),
                                end: moment(eventData.startDate).add(i, "months").toDate(),
                                title: `${eventData.title} - ${(eventData.user as IUser).firstname} ${(eventData.user as IUser).lastname}`,
                                id: mergedEvents.length,
                                allDay: true
                            });
                        }
                        break;
                    case EScheduleOption.YEARLY:
                        const years = eventData.endDate ? (moment(eventData.endDate).diff(eventData.startDate, "days") + 1) / 365 : 1000;
                        for (let i = 0; i <= years; i++) {
                            mergedEvents.push({
                                start: moment(eventData.startDate).add(i, "years").toDate(),
                                end: moment(eventData.startDate).add(i, "years").toDate(),
                                title: `${eventData.title} - ${(eventData.user as IUser).firstname} ${(eventData.user as IUser).lastname}`,
                                id: mergedEvents.length,
                                allDay: true
                            });
                        }
                        break;
                }
            });
        }
        setEvents(mergedEvents);
    }

    const columns: Column<any>[] = [
        {
            Header: "Esemény",
            accessor: "title",
            Cell: (data: any) => {
                return (
                    <div>
                        <p>
                            {data.value}
                        </p>
                    </div>
                )
            },
            disableFilters: true,
        },
        {
            Header: "Felhasználó",
            accessor: "user",
            Cell: (data: any, index:number) => {
                return (
                    <div>
                        <p>
                            {`${data.value.firstname} ${data.value.lastname}`}
                        </p>
                    </div>
                )
            },
            disableFilters: true,
        },
        {
            Header: "Kezdés dátuma",
            accessor: "startDate",
            Cell: (data: any, index:number) => {
                return (
                    <div>
                        <p>
                            {moment(data.value).format("YYYY-MM-DD")}
                        </p>
                    </div>
                )
            },
            disableFilters: true,
        },
        {
            Header: "Vég. dátuma",
            accessor: "endDate",
            Cell: (data: any, index:number) => {
                return (
                    <div>
                        <p>
                            {data.value ? moment(data.value).format("YYYY-MM-DD") : "-"}
                        </p>
                    </div>
                )
            },
            disableFilters: true,
        },
        {
            Header: "Ismétlődés",
            accessor: "repeat",
            Cell: (data: any, index:number) => {
                return (
                    <div>
                        <p>
                            {data.value}
                        </p>
                    </div>
                )
            },
            disableFilters: true,
        },
    ]

    function handleSelectEvent(event: any) {
        switch (event.type) {
            case EventTypes.CLEANING:
                history.push(`/admin/event/${event.id}`)
                break
        }
    }

    function openScheduleModal() {
        setShowScheduleModal(true);
    }

    return (
        <>
            <div>
                <Row>
                    <Col xs={12} md={10}>
                        <div className="tile">
                            <EventCalendar
                                events={events}
                                onSelectSlotFunction={(date: any) => {
                                    setSelectedDate(date)
                                }}
                                handleSelectEvent={handleSelectEvent}
                            />
                        </div>
                    </Col>
                    <Col xs={12} md={2}>
                        <Button onClick={openScheduleModal} className="btn orange">Esemény ütemezése</Button>
                    </Col>
                </Row>
                <Row>
                    <TableDisplayer columns={columns} data={scheduledEvents} />
                </Row>
            </div>
            <ScheduleEventModal show={showScheduleModal} setShow={setShowScheduleModal} events={events} setEvents={setEvents} selectedDate={selectedDate} spot={props.cube} setScheduledEvents={setScheduledEvents} scheduledEvents={scheduledEvents} />
        </>
    )
}

export default CubeCalendar
