import RegionAdminDetailsHome from "../../components/regionadmins/regionadmin-details-home.component"
import RegionAdminUpdate from "../../components/regionadmins/regionadmin-update.component"
import { ISubPageRoute } from "../../interfaces/pages"

const regionadminDetailsRoutes: ISubPageRoute[] = [
    {
        path: "/admin/regionadmins/:id",
        name: "Details",
        component: RegionAdminDetailsHome,
    },
    {
        path: "/admin/regionadmins/:id/update",
        name: "Edit",
        component: RegionAdminUpdate,
    },
]

export default regionadminDetailsRoutes
