//  Modules from the React ecosystem
import { useState, useEffect } from "react"

// Own components
import EventsTable from "../events/event-table.component"

// Axios
import axiosClient from "../../api/api"

// Interfaces, enums
import { IEvent, ICube } from "../../interfaces/cubes"

// Styles, bootstrap, icons
import { Row, Col } from "react-bootstrap"

interface IProps {
    cube: ICube
    setCube: Function
}
/**
 * component to show the reservations of the current cube
 * @param props
 * @returns
 */
function CubeEvents(props: IProps) {
    // reservations of the cube from the fetch
    const [events, setEvents] = useState<IEvent[]>([])

    useEffect(() => {
        // fetch events
        fetchEvents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cube])

    /**
     * fetch reservations of the current cube
     */
    async function fetchEvents() {
        try {
            const response = await axiosClient.get(`/event/list?cube=${props.cube._id}`)
            setEvents(response.data)
        } catch (error) {
            console.log("Failed to fetch events")
        }
    }
    return (
        <Row>
            <Col>
                <div className="tile">
                    <EventsTable spotId={props.cube._id} events={events} hiddenColumns={["spot", "id", "user_note", "supervised_note"]} />
                </div>
            </Col>
        </Row>
    )
}

export default CubeEvents
