//  Modules from the React eco-system
import React, { useState, useEffect } from "react"

// axios
import axiosClient from "../../api/api"

// Interfaces, enums
import { IEvent } from "../../interfaces/cubes"
import { IUser } from "../../interfaces/persons"

// Styles, bootstrap, icons
import { Row, Col } from "react-bootstrap"
import EventsTable from "../events/event-table.component";

interface IProps {
    user: IUser
}

/**
 * display reservations of one user
 * @param props user
 * @returns
 */
function UserEvents(props: IProps) {
    // reservations of the user from the database
    const [events, setEvents] = useState<IEvent[]>([])

    useEffect(() => {
        // fetch events
        fetchEvents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * fetch reservations of the user from the database
     */
    async function fetchEvents() {
        try {
            const response = await axiosClient.get(`/event/list?user=${props.user._id}`)
            setEvents(response.data)
        } catch (error) {
            console.log("Failed to fetch events")
        }
    }
    return (
        <Row>
            <Col>
                <div className="table-tile">
                    <EventsTable events={events} hiddenColumns={["spot", "id", "status", "createdAt"]} />
                </div>
            </Col>
        </Row>
    )
}

export default UserEvents
