import { Formik, FormikValues } from "formik"
import React, { useState, useEffect } from "react"
import { Form, Button, Row, Col } from "react-bootstrap"
import * as Yup from "yup"
import axiosClient from "../../api/api"
import { IRegion } from "../../interfaces/cubes"
import { IRegionAdmin } from "../../interfaces/persons"
import Select from "react-select"
import { AxiosError } from "axios"
import { toast } from "react-toastify"

interface IProps {
    setRegionAdmins: Function
}

const FormInitialValues = {
    firstname: "",
    lastname: "",
    email: "",
    regions: [],
    company: "",
    phone: "",
    taxNumber: "",
}

function CreateRegionAdmin(props: IProps) {
    const [regions, setRegions] = useState<IRegion[]>([])

    useEffect(() => {
        fetchRegions()
    }, [])

    async function fetchRegions() {
        try {
            const response = await axiosClient.get("/region?selectFields[]=name")
            setRegions(response.data)
        } catch (error) {
            toast.error("Failed to fetch regions")
        }
    }

    return (
        <div>
            <div>
                {regions.length ? (
                    <Formik
                        initialValues={FormInitialValues}
                        validationSchema={Yup.object().shape({
                            firstname: Yup.string().required("Required"),
                            lastname: Yup.string().required("Required"),
                            email: Yup.string().required("Required").email("Email is incorrect"),
                        })}
                        onSubmit={async (values: FormikValues, actions: any) => {
                            try {
                                const regionAdminData = {
                                    firstname: values.firstname,
                                    lastname: values.lastname,
                                    email: values.email,
                                    password: values.password,
                                    regions: values.regions.map((reg: any) => reg.value),
                                    company: values.company,
                                    phone: values.phone,
                                    tax_number: values.taxNumber,
                                }
                                const response = await axiosClient.post("/regionadmin", { regionAdminData: regionAdminData })
                                actions.resetForm(FormInitialValues)
                                props.setRegionAdmins((prev: IRegionAdmin[]) => [...prev, response.data])
                                toast.success("Invitation email is sent")
                            } catch (error) {
                                const err = error as AxiosError
                                toast.error(err.response?.data)
                            }
                        }}>
                        {(props: any) => (
                            <Row>
                                <Col>
                                    <div className="tile">
                                        <Form onSubmit={props.handleSubmit}>
                                            <Row>
                                                <Col xs={12} sm={3}>
                                                    <Form.Group className="mb-3" controlId="formSupplier">
                                                        <Form.Label>Firstname:</Form.Label>
                                                        <Form.Control
                                                            onChange={props.handleChange}
                                                            name="firstname"
                                                            onBlur={props.handleBlur}
                                                            value={props.values.firstname}
                                                            type="text"
                                                            placeholder="Firstname..."
                                                        />
                                                        {props.touched.firstname && props.errors.firstname ? <p className="input-error">{props.errors.firstname}</p> : null}
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={3}>
                                                    <Form.Group className="mb-3" controlId="formSupplier">
                                                        <Form.Label>Lastname</Form.Label>
                                                        <Form.Control
                                                            onChange={props.handleChange}
                                                            name="lastname"
                                                            onBlur={props.handleBlur}
                                                            value={props.values.lastname}
                                                            type="text"
                                                            placeholder="Lastname..."
                                                        />
                                                        {props.touched.lastname && props.errors.lastname ? <p className="input-error">{props.errors.lastname}</p> : null}
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={3}>
                                                    <Form.Group className="mb-3" controlId="formSupplier">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control
                                                            onChange={props.handleChange}
                                                            name="email"
                                                            onBlur={props.handleBlur}
                                                            value={props.values.email}
                                                            type="email"
                                                            placeholder="Email..."
                                                        />
                                                        {props.touched.email && props.errors.email ? <p className="input-error">{props.errors.email}</p> : null}
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={3}>
                                                    <Form.Group className="mb-3" controlId="formSupplier">
                                                        <Form.Label>Phone</Form.Label>
                                                        <Form.Control
                                                            onChange={props.handleChange}
                                                            name="phone"
                                                            onBlur={props.handleBlur}
                                                            value={props.values.phone}
                                                            type="text"
                                                            placeholder="Phone number..."
                                                        />
                                                        {props.touched.phone && props.errors.phone ? <p className="input-error">{props.errors.phone}</p> : null}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={4}>
                                                    <Form.Group className="mb-3" controlId="formSupplier">
                                                        <Form.Label>Regions</Form.Label>
                                                        {regions && (
                                                            <Select isMulti
                                                                options={regions.map((reg) => {
                                                                    return { value: reg._id, label: reg.name }
                                                                })}
                                                                onChange={(t) => props.setFieldValue("regions", [...t])}
                                                                value={props.values.regions}
                                                                name="regions"
                                                            />
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={4}>
                                                    <Form.Group className="mb-3" controlId="formSupplier">
                                                        <Form.Label>Company</Form.Label>
                                                        <Form.Control
                                                            onChange={props.handleChange}
                                                            name="company"
                                                            onBlur={props.handleBlur}
                                                            value={props.values.company}
                                                            type="text"
                                                            placeholder="Company..."
                                                        />
                                                        {props.touched.company && props.errors.company ? <p className="input-error">{props.errors.company}</p> : null}
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={4}>
                                                    <Form.Group className="mb-3" controlId="formSupplier">
                                                        <Form.Label>Tax number</Form.Label>
                                                        <Form.Control
                                                            onChange={props.handleChange}
                                                            name="taxNumber"
                                                            onBlur={props.handleBlur}
                                                            value={props.values.taxNumber}
                                                            type="text"
                                                            placeholder="Tax number..."
                                                        />
                                                        {props.touched.taxNumber && props.errors.taxNumber ? <p className="input-error">{props.errors.taxNumber}</p> : null}
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Button className="orange" type="submit">
                                                Submit
                                            </Button>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Formik>
                ) : (
                    <p>Create a region first</p>
                )}
            </div>
        </div>
    )
}

export default CreateRegionAdmin
