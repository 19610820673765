//  Modules from the React eco-system
import { useMemo } from "react"
import { Link } from "react-router-dom"

// Third-party modules
import moment from "moment"

// Own components
import TableDisplayer from "../table.component"
import { dateFilter, textFilter, selectColumnFilter } from "../column-filter.component"

// Interfaces, enums
import { IUser } from "../../interfaces/persons"

// Styles, bootstrap, icons
import Icon from "@mdi/react"
import { mdiTune } from "@mdi/js"
import { Row, Col } from "react-bootstrap"
import {useTranslation} from "react-i18next";

interface IProps {
    users: IUser[]
    hiddenColumns?: string[]
}

function UsersTable(props: IProps) {
    const { t } = useTranslation();
    const columns = useMemo(
        () => [
            {
                Header: t("Details"),
                Cell: (data: any) => {
                    return (
                        <div>
                            <p>
                                <Link to={`/admin/users/${data.row.original._id}`}>
                                    <span className="icon-container btn orange">
                                        <Icon path={mdiTune} size={1} />
                                    </span>{" "}
                                </Link>

                                {data.value}
                            </p>
                        </div>
                    )
                },
                disableFilters: true,
            },
            {
                Header: t("Name"),
                accessor: (data: IUser) => {
                    return <Link to={`/admin/users/${data._id}`}>{`${data.firstname} ${data.lastname}`}</Link>
                },
                Filter: textFilter,
                isHidden: props.hiddenColumns?.includes("name"),
            },
            {
                Header: t("Email"),
                accessor: "email",
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("email"),
            },
            {
                Header: "Number of Events",
                accessor: "events.length",
                isHidden: props.hiddenColumns?.includes("numberOfEvents"),
                disableFilters: true,
            },
            {
                Header: "Id",
                accessor: "_id",
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("id"),
            },
            {
                Header: "Gender",
                accessor: "gender",
                Filter: selectColumnFilter,
                isHidden: props.hiddenColumns?.includes("gender"),
            },
            {
                Header: "Birthdate",
                accessor: "birthdate",
                Cell: (data: any) => {
                    return <p>{moment(data.value).format("YYYY. MM. DD HH:mm")}</p>
                },
                Filter: dateFilter,
                isHidden: props.hiddenColumns?.includes("birthdate"),
            },
            {
                Header: "Country",
                accessor: "country.name",
                Filter: selectColumnFilter,
                isHidden: props.hiddenColumns?.includes("country"),
            },
            {
                Header: t("Phone"),
                accessor: "phone",
                disableFilters: true,
                isHidden: props.hiddenColumns?.includes("phone"),
            },
            {
                Header: "Registered at",
                accessor: "createdAt",
                Cell: (data: any) => {
                    return <p>{moment(data.value).format("YYYY. MM. DD HH:mm")}</p>
                },
                Filter: dateFilter,
                isHidden: props.hiddenColumns?.includes("registeredAt"),
            },
        ],

        [props.hiddenColumns]
    )

    return (
        <Row>
            <Col>
                <div className="table-tile">{props.users && <TableDisplayer columns={columns} data={props.users} />}</div>
            </Col>
        </Row>
    )
}

export default UsersTable
